import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { selectAuth } from 'store/auth/selector'

interface Props {
  component: React.ComponentType
  path?: string
}

export const PublicRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const { isAuthenticated } = useSelector(selectAuth)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

  useEffect(() => {
    setIsLoggedIn(isAuthenticated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoggedIn ? <Navigate to="/" /> : <RouteComponent />
}
