import { DefaultTheme } from 'styled-components'

export const appTheme: DefaultTheme = {
  borderRadius: '4px',
  palette: {
    common: {
      black: '#0B0F16',
      white: '#ffffff',
    },
    primary: {
      main: '#14182d',
      light: '#282B49',
      input: '#393b4d',
      adBackground: '#2E3240',
      contentBg: '#14182d',
      footerBg: '#0B0D1C',
    },
    secondary: {
      btn: '#c00a2e',
    },
  },
}
