import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/Common'
import { ReactComponent as SwaraLogo } from '../../assets/swara-logo.svg'
import UserLogo from 'assets/user-profile.svg'
// import { logoutRequest } from 'store/auth/slice'
import { actions } from 'store/category/slice'
import { selectCategory } from 'store/category/selector'
import { selectAuth } from 'store/auth/selector'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getApiLoaderState } from 'utils/helpers/functions'
import Loader from 'components/Common/Loader'
import {
  getGlobalSearchRequest,
  resetSearchInfoRequest,
} from 'store/common/slice'
import _ from 'lodash'
import './mobileHeader.css'
import { StyledChips } from 'containers/Profile/StyledComponents'
import { selectUser } from 'store/user/selector'

function MobileHeader() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { categoryType = 'all' } = useParams()
  const [searchValue, setSearchValue] = useState('')
  const location = useLocation()
  const isSolidBg: boolean = ['watch'].includes(categoryType)

  const { categories, selectedCategory } = useSelector(selectCategory)
  const { isAuthenticated } = useSelector(selectAuth)
  const { userInfo } = useSelector(selectUser)

  useEffect(() => {
    if (categories.length) {
      dispatch(
        actions.setSelectedCategory(
          categories.filter(
            category => categoryType === category.categoryName,
          )?.[0],
        ),
      )
    }

    return () => {
      dispatch(actions.setSelectedCategory(null))
    }
  }, [categories, dispatch, categoryType])

  const onHeaderItemClick = (category: any) => {
    const categoryType = category.name.replace(/\s+/g, '-').toLowerCase()
    navigate(`/services/${categoryType}`)
    setSearchValue('')
    dispatch(resetSearchInfoRequest())
    dispatch(actions.setSelectedCategory(category))
  }

  const onHeaderWatchListClick = () => {
    setSearchValue('')
    navigate(`/watch-later`)
  }

  const handleSearchData = (e: any) => {
    setSearchValue(e.target.value)
    debouncedGetSearchData(e.target.value)
  }

  const debouncedGetSearchData = useMemo(
    () =>
      _.debounce(value => {
        if (value !== '' && value?.length > 2) {
          dispatch(getGlobalSearchRequest({ searchValue: value }))
        } else if (value === '') {
          dispatch(resetSearchInfoRequest())
        }
      }, 1000),
    [dispatch],
  )

  const categoriesApi = getApiLoaderState('/home/categories')
  const isWatchListActive = ['/watch-later'].some(key =>
    location.pathname.includes(key),
  )
  return categoriesApi.loading ? (
    <Loader />
  ) : (
    <div
      className={`mb-header-container row ${
        isSolidBg ? 'solidBg' : 'gradientBg'
      } `}
    >
      <div
        className={`header-style profile-section ${
          isSolidBg ? 'solidBg' : 'gradientBg'
        } col-12 `}
      >
        <div className="logo-layout">
          <SwaraLogo
            className="logo cursor-pointer"
            onClick={() => {
              setSearchValue('')
              dispatch(resetSearchInfoRequest())
              navigate('/')
            }}
          />
        </div>
        <div className="header-right-container">
          <input
            className="search-input"
            value={searchValue}
            onChange={e => handleSearchData(e)}
          />
          {isAuthenticated ? (
            <img
              className="profile-header"
              src={userInfo?.profileImageUrl || UserLogo}
              onClick={() => navigate('/profile')}
            />
          ) : (
            <Button
              style={{ width: '25px' }}
              onClick={() => navigate('/login')}
            >
              Sign In
            </Button>
          )}
        </div>
      </div>
      <div
        className="col-10"
        style={{
          overflowX: 'auto',
        }}
      >
        {categories.map((category, index) => {
          const isActive = selectedCategory?._id === category._id
          return (
            <StyledChips
              key={index}
              id={category._id}
              isCategory={true}
              isSelected={isActive}
              onClick={() => onHeaderItemClick(category)}
              className="category-chips"
            >
              {category.name}
            </StyledChips>
          )
        })}
        {isAuthenticated && categories?.length > 0 ? (
          <StyledChips
            key={categories?.length}
            isCategory={true}
            isSelected={isWatchListActive}
            onClick={() => onHeaderWatchListClick()}
            className="category-chips"
          >
            Watch list
          </StyledChips>
        ) : null}
      </div>
    </div>
  )
}

export default MobileHeader
