import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { toast } from 'react-toastify'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { actions as userActions } from 'store/user/slice'
import {
  loginRequest,
  registerRequest,
  forgotPaswordRequest,
  verifyForgotPasswordRequest,
  verifyRegistrationRequest,
  resendVerificationCodeRequest,
  verifyFederatedLoginRequest,
  actions,
  logoutRequest,
} from './slice'

function* login({ payload: { data, navigate, from } }: any): any {
  try {
    const response = yield call(baseAxios.post, apiEndPoints.auth.login, data)

    if (response.data.token) {
      yield put(actions.loggedIn(response.data))
      localStorage.setItem('TOKEN', response.data.token)
      localStorage.setItem('USER_DATA', JSON.stringify(response.data))
      const preferenceResponse = yield call(
        baseAxios.get,
        apiEndPoints.user.userPreferences,
      )
      yield put(userActions.setUserPreferences(preferenceResponse.data))
      navigate(from)
    }
  } catch (err) {
    console.log(err)
  }
}

function* register({ payload: { data, navigate } }: any): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.auth.register,
      data,
    )

    if (response) {
      navigate('/verify-email', { state: { email: data.email } })
    }
  } catch (err) {
    console.log(err)
  }
}

function* verifyRegistration({ payload: { data, navigate } }: any): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.auth.verifyRegistration,
      { ...data, email: data.email },
    )

    if (response) {
      navigate('/login')
      toast('Registered Successfully!')
    }
  } catch (err) {
    console.log(err)
  }
}

function* forgotPassword({ payload: { data, navigate } }: any): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.auth.forgotPassword,
      data,
    )
    if (response.data) {
      navigate('/reset-password', { state: { email: data.email } })
    }
  } catch (err) {
    console.log(err)
  }
}

function* verifyForgotPassword({ payload: { data, navigate } }: any): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.auth.verifyForgotPassword,
      data,
    )
    if (response.data) {
      navigate('/login')
    }
  } catch (err) {
    console.log(err)
  }
}

function* resendVerificationCode({ payload: { data } }: any): any {
  try {
    yield call(baseAxios.post, apiEndPoints.auth.resendVerificationCode, data)
    toast.success('Code Sent Successfully!')
  } catch (err) {
    console.log(err)
  }
}

function* logout({ payload: { navigate } }: any): any {
  try {
    const response = yield call(baseAxios.post, apiEndPoints.auth.logout, {})
    if (response) {
      yield put(actions.loggedOut())
      localStorage.removeItem('TOKEN')
      localStorage.removeItem('USER_DATA')
    }
  } catch (err) {
    console.log(err)
  }
}

function* verifyFederatedLogin({ payload: { data, navigate } }: any): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.auth.verifyFederatedLogin,
      data,
    )
    if (response.data) {
      yield put(actions.loggedIn(response.data))
      localStorage.setItem('TOKEN', response.data.token)
      localStorage.setItem('USER_DATA', JSON.stringify(response.data))
      const preferenceResponse = yield call(
        baseAxios.get,
        apiEndPoints.user.userPreferences,
      )
      yield put(userActions.setUserPreferences(preferenceResponse.data))
      navigate('/')
    }
  } catch (err) {
    console.log(err)
  }
}

export function* authSaga(): any {
  yield all([
    yield takeLatest(loginRequest, login),
    yield takeLatest(registerRequest, register),
    yield takeLatest(forgotPaswordRequest, forgotPassword),
    yield takeLatest(verifyForgotPasswordRequest, verifyForgotPassword),
    yield takeLatest(verifyRegistrationRequest, verifyRegistration),
    yield takeLatest(resendVerificationCodeRequest, resendVerificationCode),
    yield takeLatest(logoutRequest, logout),
    yield takeLatest(verifyFederatedLoginRequest, verifyFederatedLogin),
  ])
}
