import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ChangePasswordPayload,
  EditUserPreferencesPayload,
  UserInfoPayload,
  UserState,
} from './types'

const getUserInfoRequest = createAction('user/getUserInfoRequest')
const updateUserInfoRequest = createAction<UserInfoPayload>(
  'user/updateUserInfoRequest',
)
const changePasswordRequest = createAction<ChangePasswordPayload>(
  'user/changePasswordRequest',
)
const listPreferencesRequest = createAction('user/listPreferencesRequest')
const getUserPreferencesRequest = createAction('user/getUserPreferencesRequest')
const createUserPreferencesRequest = createAction<string[]>(
  'user/createUserPreferencesRequest',
)
const editUserPreferencesRequest = createAction<EditUserPreferencesPayload>(
  'user/getPreferencesRequest',
)

export const initialState: UserState = {
  userInfo: null,
  preferences: [],
  userPreferences: {},
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state: UserState, action: PayloadAction<any>) {
      state.userInfo = action.payload
    },
    setPreferences(state: UserState, action: PayloadAction<any>) {
      state.preferences = action.payload
    },
    setUserPreferences(state: UserState, action: PayloadAction<any>) {
      state.userPreferences = action.payload
    },
  },
})

export {
  getUserInfoRequest,
  updateUserInfoRequest,
  changePasswordRequest,
  listPreferencesRequest,
  getUserPreferencesRequest,
  createUserPreferencesRequest,
  editUserPreferencesRequest,
}
export const { actions, reducer, name: sliceKey } = userSlice
