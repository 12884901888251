import React, { useCallback, useEffect, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import {
  StyledTrayContainer,
  StyledTrayHeader,
  StyledTrayList,
} from './StyledComponents'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SkeltonLoader from '../SkeltonLoader'
import PrevArrow from 'assets/prevArrow.png'
import NextArrow from 'assets/nextArrow.png'
import { CardTypes } from 'utils/helpers/constants'

interface SliderTrayProps {
  headerText: string
  children: any
  recordsPerSlide: number
  isMoreData: boolean
  loading: boolean
  className?: any
  cardType?: number
  onSlideChange(skip: number, limit: number): void
}

const Index = ({
  headerText,
  recordsPerSlide,
  isMoreData,
  cardType = CardTypes.MOVIE,
  onSlideChange,
  loading,
  children,
  className,
}: SliderTrayProps) => {
  // const sliderSlickRef = useRef<Slider | null>(null)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [filters, setFilters] = useState({
    skip: 0,
    //TODO:please clean this up, quick hack for demo purpouse
    limit: 30 * 2,
    slidesToShow: recordsPerSlide || 8,
  })

  useEffect(() => {
    onSlideChange(filters.skip, filters.limit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    setFilters(s => ({
      ...s,
      //TODO:please clean this up, quick hack for demo purpouse
      limit: 30 * 2,
      slidesToShow: recordsPerSlide || 8,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsPerSlide])

  const onNextSlide = useCallback(() => {
    if (isMoreData) {
      setFilters(state => ({
        ...state,
        skip: state.limit - 1,
      }))
    }
  }, [isMoreData, setFilters])

  const slidesForBreakpoints = {
    [CardTypes.MOVIE_RECOMMENDATIONS.toString()]: {
      1400: 5,
      1200: 4,
      992: 4,
      768: 3,
      576: 3,
      480: 2,
      340: 2,
      className: 'recommendations-card',
    },
    [CardTypes.CONTINUE_CARD.toString()]: {
      1400: 6,
      1200: 5,
      992: 4,
      768: 3,
      576: 2,
      480: 2,
      340: 1,
      className: 'continue-card',
    },
    [CardTypes.MOVIE.toString()]: {
      1400: 8,
      1200: 7,
      992: 6,
      768: 5,
      576: 4,
      480: 3,
      340: 1,
      className: 'generic-card',
    },
  }

  const sliderSettings: Settings = {
    centerMode: false,
    infinite: false,
    arrows: true,
    draggable: false,
    afterChange: onNextSlide,
    centerPadding: '90px',
    prevArrow: <img src={PrevArrow} />,
    nextArrow: <img src={NextArrow} />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: slidesForBreakpoints[cardType.toString()][1400],
          slidesToScroll: slidesForBreakpoints[cardType.toString()][1400],
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: slidesForBreakpoints[cardType.toString()][1200],
          slidesToScroll: slidesForBreakpoints[cardType.toString()][1200],
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: slidesForBreakpoints[cardType.toString()][992],
          slidesToScroll: slidesForBreakpoints[cardType.toString()][992],
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesForBreakpoints[cardType.toString()][768],
          slidesToScroll: slidesForBreakpoints[cardType.toString()][768],
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: slidesForBreakpoints[cardType.toString()][576],
          slidesToScroll: slidesForBreakpoints[cardType.toString()][576],
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: slidesForBreakpoints[cardType.toString()][480],
          slidesToScroll: slidesForBreakpoints[cardType.toString()][480],
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: slidesForBreakpoints[cardType.toString()][340],
          slidesToScroll: slidesForBreakpoints[cardType.toString()][340],
          infinite: false,
          dots: false,
        },
      },
    ],
  }

  const getSkeletonCount = () => {
    return screenWidth <= 340
      ? slidesForBreakpoints[cardType.toString()][340]
      : screenWidth <= 480
      ? slidesForBreakpoints[cardType.toString()][480]
      : screenWidth <= 576
      ? slidesForBreakpoints[cardType.toString()][576]
      : screenWidth <= 768
      ? slidesForBreakpoints[cardType.toString()][768]
      : screenWidth <= 992
      ? slidesForBreakpoints[cardType.toString()][992]
      : screenWidth <= 1200
      ? slidesForBreakpoints[cardType.toString()][1200]
      : screenWidth <= 1400
      ? slidesForBreakpoints[cardType.toString()][1400]
      : recordsPerSlide
  }
  return (
    <StyledTrayContainer>
      <StyledTrayHeader>{headerText}</StyledTrayHeader>
      <StyledTrayList>
        {loading ? (
          <div className="skelton-container">
            <SkeltonLoader
              count={getSkeletonCount()}
              className={`${
                slidesForBreakpoints[cardType.toString()].className
              } mr-4`}
              // width={170}
              // height={240}
            />
          </div>
        ) : (
          <Slider
            slidesToShow={filters.slidesToShow}
            slidesToScroll={filters.slidesToShow}
            {...sliderSettings}
            className={className ? className : ''}
          >
            {children}
          </Slider>
        )}
      </StyledTrayList>
    </StyledTrayContainer>
  )
}

export default Index
