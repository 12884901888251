import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectAuth } from 'store/auth/selector'
import { selectCommon } from 'store/common/selector'
import { selectMusic } from 'store/music/selector'
import { actions, resetMusicTrackDetailsRequest } from 'store/music/slice'
import { localStorageRemoveMusicData } from 'utils/helpers/functions'
import RadioIcon from '../../assets/radio-icon.png'
import {
  StyledCircelWave,
  StyledRadioBtn,
  StyledRadioImg,
} from './StyledComponents'

const Index = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { currentTrackDetails, radioPlaying } = useSelector(selectMusic)

  const { isAuthenticated } = useSelector(selectAuth)
  const {
    networkCall: { loading },
  } = useSelector(selectCommon)

  const audioRef = useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    radioPlaying ? audioRef?.current?.play() : audioRef?.current?.pause()
  }, [radioPlaying])

  const onRadioClick = () => {
    if (!loading) {
      if (isAuthenticated) {
        dispatch(resetMusicTrackDetailsRequest())
        dispatch(actions.setAudioPlayerActive(true))
        dispatch(actions.setRadioPlaying(!radioPlaying))
        dispatch(actions.setVideoPlaying(false))
        localStorageRemoveMusicData()
      } else navigate(`/login`, { state: { from: pathname } })
    }
  }

  return (
    <>
      <h5
        className="text-white"
        style={{ position: 'fixed', bottom: -8, right: 22, fontSize: 16 }}
        // style={{ position: 'fixed', bottom: 80, right: 30, fontSize: 16 }}
      >
        Radio
      </h5>
      <StyledRadioBtn
        isRadioOn={radioPlaying}
        positionValue={
          currentTrackDetails &&
          Object.keys(currentTrackDetails).length > 0 &&
          isAuthenticated
            ? '100px'
            : '20px'
        }
        onClick={onRadioClick}
        // title="Radio"
      >
        <audio
          id="radioPlayer"
          ref={audioRef}
          src="https://s3.radio.co/s162c52c51/listen"
          preload="true"
          controls
          style={{
            position: 'absolute',
            opacity: 0,
            maxWidth: '1px',
            minWidth: '1px !important',
            width: '1px',
          }}
        ></audio>
        <StyledCircelWave />
        <StyledCircelWave />
        <StyledCircelWave />
        <StyledCircelWave />
        <StyledRadioImg src={RadioIcon} />
      </StyledRadioBtn>
    </>
  )
}

export default Index
