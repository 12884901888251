import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAuth } from 'store/auth/selector'
import Header from 'components/Layouts/Header'
import MobileHeader from 'components/Layouts/MobileHeader'
import Footer from 'components/Layouts/Footer'
import useWindowSize from 'hooks/useWindowSize'

interface Props {
  component: React.ComponentType
  path?: string
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
}) => {
  const { isAuthenticated } = useSelector(selectAuth)
  const [width] = useWindowSize()
  const showMobileHeader = useMemo(() => width <= 1024, [width])
  if (isAuthenticated) {
    return (
      <div className="rootDiv">
        {width !== 0 && showMobileHeader ? <MobileHeader /> : <Header />}
        <div
          style={{
            minHeight: width !== 0 && showMobileHeader ? '30vh' : '80vh',
            backgroundColor: '#14182d',
          }}
        >
          <RouteComponent />
        </div>
        <Footer />
      </div>
    )
  }

  return <Navigate to="/login" />
}
