import React from 'react'
import { useNavigate } from 'react-router'
import FbMonochrome from 'assets/monochrome-fb.svg'
import linkedInMonoChrome from 'assets/monochrome-linkedin.svg'
import instagramMonoChrome from 'assets/monochrome-instagram.svg'
import twiiterMonoChrome from 'assets/monochrome-twitter.svg'

function App() {
  const navigate = useNavigate()

  return (
    <footer className="site-footer">
      <div className="container">
        <div
          className="row"
          style={{ justifyContent: 'space-between', width: '100%' }}
        >
          <div className="col-6 col-md-4 col-lg-3">
            <h6>Navigation</h6>
            <ul className="footer-links">
              <li>
                <a href="http://swara.video">Home</a>
              </li>
              {/* <li>
                <a target="_blank" href="http://swara.media">
                  FAQ
                </a>
              </li>
              <li>
                <a target="_blank" href="http://swara.media">
                  Investor Relations
                </a>
              </li>
              <li>
                <a target="_blank" href="http://swara.media">
                  Jobs
                </a>
              </li> */}
              <li>
                <a
                  target="_blank"
                  onClick={() => navigate('/about-us')}
                  className="footer-item"
                >
                  About Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg-3">
            <h6>LEGAL</h6>
            <ul className="footer-links">
              <li>
                <a
                  target="_blank"
                  onClick={() => navigate('/privacy-policy')}
                  className="footer-item"
                >
                  Privacy Policy
                </a>
              </li>
              {/* <li>
                <a target="_blank" href="http://swara.media">
                  Terms of Service
                </a>
              </li> */}

              {/* <li>
                <a target="_blank" href="http://swara.media">
                  Corporate Information
                </a>
              </li> */}
            </ul>
          </div>

          <div className="col-6 col-md-4 col-lg-3">
            <h6>TALK TO US</h6>
            <ul className="footer-links">
              <li>
                <a target="_blank" href="mailto:hello@swara.media">
                  hello@swara.media
                </a>
              </li>
              <li>
                <a target="_blank" href="tel:+19876547865">
                  +1- (530) 444 - 9125
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg-3">
            <h6>FOLLOW US</h6>
            <div className="social-icons-container">
              <a target="_blank" href="https://www.facebook.com/TheSwaraMedia/">
                <img className="social-icon" src={FbMonochrome} />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/TheSwaraMedia/"
              >
                <img className="social-icon" src={instagramMonoChrome} />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/theswaramedia/"
              >
                <img className="social-icon" src={linkedInMonoChrome} />
              </a>
              <a target="_blank" href="https://twitter.com/TheSwaraMedia">
                <img className="social-icon" src={twiiterMonoChrome} />
              </a>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-3">
            <div className="app-icons">
              <a href="https://apps.apple.com/us/app/swara-media/id1472045249">
                <img
                  className="apple"
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                  alt="Download on the App Store"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.swararadioapp">
                <img
                  className="android"
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-container">
        <p className="copyright-text">
          Copyright &copy; 2023 All Rights Reserved.
        </p>
      </div>
    </footer>
  )
}

export default App
