import React, { useEffect, useRef, useState } from 'react'
import { selectMusic } from 'store/music/selector'
import { useDispatch, useSelector } from 'react-redux'
import AudioPlayerContainer from 'components/Common/AudioPlayer'
import { actions, captureAudioActivityRequest } from 'store/music/slice'
import { selectAuth } from 'store/auth/selector'
import {
  localStorageGetItem,
  localStorageRemoveMusicData,
} from 'utils/helpers/functions'

const Index = () => {
  const { isAuthenticated } = useSelector(selectAuth)
  const playerRef = useRef<any>(null)
  const dispatch = useDispatch()
  const { trackList, currentTrackDetails, trackPlaying, radioPlaying } =
    useSelector(selectMusic)
  const [currentSong, setCurrentSong] = useState<any>(null)

  function handlePlayNextSong(track: any) {
    const currentTrackIndex = trackList.findIndex((at: any) => {
      return at._id === track._id
    })
    if (currentTrackIndex < trackList.length - 1) {
      setCurrentSong(trackList[currentTrackIndex + 1])
      dispatch(actions.setCurrentTrackDetails(trackList[currentTrackIndex + 1]))
    } else {
      setCurrentSong(trackList[0])
      dispatch(actions.setCurrentTrackDetails(trackList[0]))
    }
  }

  function handlePlayPreviousSong(track: any) {
    const currentTrackIndex = trackList.findIndex((at: any) => {
      return at._id === track._id
    })
    if (currentTrackIndex > 0) {
      setCurrentSong(trackList[currentTrackIndex - 1])
      dispatch(actions.setCurrentTrackDetails(trackList[currentTrackIndex - 1]))
    }
  }

  function handlePlay() {
    dispatch(actions.setRadioPlaying(false))
    dispatch(actions.setAudioPlayerActive(true))
    dispatch(actions.setTrackPlaying(true))
  }

  function handlePause() {
    if (playerRef?.current) {
      playerRef.current.audio.current.pause()
      const lastAudioTrack = {
        audioId: currentSong._id,
        audioPlayedTime: `${Math.floor(
          playerRef.current.audio.current.currentTime,
        )}`,
      }
      localStorage.setItem('lastAudioTrack', JSON.stringify(lastAudioTrack))
      const payload = {
        audioTrackId: currentSong._id,
        albumId: currentSong.albumId,
        lastWatchedTime: Math.floor(
          playerRef.current.audio.current.currentTime,
        ),
        timeSpent: 5,
      }
      dispatch(captureAudioActivityRequest(payload))
    }
    dispatch(actions.setTrackPlaying(false))
  }

  useEffect(() => {
    setCurrentSong(null)
    if (Object.keys(currentTrackDetails).length > 0) {
      setCurrentSong(currentTrackDetails)

      // TODO: Need to replace with correct prop
      setTimeout(() => {
        const lastAudioTrack = localStorageGetItem('lastAudioTrack')
        if (
          currentTrackDetails?.lastWatchedTime &&
          playerRef?.current &&
          lastAudioTrack?.audioPlayedTime
        ) {
          const lastWatchedTime =
            lastAudioTrack?.audioId === currentTrackDetails._id
              ? lastAudioTrack?.audioPlayedTime
              : currentTrackDetails.lastWatchedTime
          playerRef.current.audio.current.currentTime = lastWatchedTime
        }
      }, 100)

      if (!trackPlaying && playerRef?.current) {
        playerRef.current.audio.current.pause()
      } else if (playerRef?.current && trackPlaying) {
        playerRef.current.audio.current.play()
      }
    }
  }, [dispatch, currentTrackDetails, trackPlaying])

  function handleListen() {
    const lastAudioTrack = {
      audioId: currentSong._id,
      audioPlayedTime: `${Math.floor(
        playerRef.current.audio.current.currentTime,
      )}`,
    }
    localStorage.setItem('lastAudioTrack', JSON.stringify(lastAudioTrack))
    const payload = {
      audioTrackId: currentSong._id,
      albumId: currentSong.albumId,
      lastWatchedTime: Math.ceil(playerRef.current.audio.current.currentTime),
      timeSpent: 5,
    }
    dispatch(captureAudioActivityRequest(payload))
  }

  useEffect(() => {
    if (radioPlaying && playerRef?.current?.audio) {
      playerRef.current.audio.current.pause()
    }
  }, [dispatch, radioPlaying])

  function handleClose() {
    if (playerRef?.current?.audio) {
      playerRef.current.audio.current.pause()
    }
    setCurrentSong(null)
    dispatch(actions.setRadioPlaying(false))
    dispatch(actions.setAudioPlayerActive(true))
    dispatch(actions.setTrackPlaying(false))
    dispatch(actions.setCurrentTrackDetails({}))
    localStorageRemoveMusicData()
  }

  return (
    <>
      {isAuthenticated && currentSong && !radioPlaying ? (
        <AudioPlayerContainer
          file={currentSong?.audioInfo?.audioUrl}
          imageUrl={currentSong?.imageUrl}
          name={currentSong?.name}
          playing={trackPlaying}
          description={currentSong?.description}
          onPlayNextSong={() => handlePlayNextSong(currentSong)}
          onPlayPreviousSong={() => handlePlayPreviousSong(currentSong)}
          onPlaySong={() => handlePlay()}
          onPauseSong={() => handlePause()}
          onListen={() => handleListen()}
          onClose={() => handleClose()}
          playerRef={playerRef}
        />
      ) : null}
    </>
  )
}

export default Index
