import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/Common'
import { ReactComponent as SwaraLogo } from '../../assets/swara-logo.svg'
import UserLogo from 'assets/user-profile.svg'
// import { logoutRequest } from 'store/auth/slice'
import { actions, categoriesRequest } from 'store/category/slice'
import { selectCategory } from 'store/category/selector'
import { selectAuth } from 'store/auth/selector'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getApiLoaderState } from 'utils/helpers/functions'
import Loader from 'components/Common/Loader'
import {
  getGlobalSearchRequest,
  resetSearchInfoRequest,
} from 'store/common/slice'
import _ from 'lodash'
import { selectUser } from 'store/user/selector'

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { categoryType = 'all' } = useParams()
  const [searchValue, setSearchValue] = useState('')
  const location = useLocation()
  const isSolidBg: boolean = ['watch'].includes(categoryType)

  const { categories, selectedCategory } = useSelector(selectCategory)
  const { isAuthenticated } = useSelector(selectAuth)
  const { userInfo } = useSelector(selectUser)

  useEffect(() => {
    !categories.length && dispatch(categoriesRequest())
  }, [categories, dispatch])

  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY
      if (offset > 0) {
        setHasScrolled(true)
      } else {
        setHasScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    // set the selectedcategory from routes
    if (categories.length) {
      dispatch(
        actions.setSelectedCategory(
          categories.filter(
            category => categoryType === category.categoryName,
          )?.[0],
        ),
      )
    }

    return () => {
      dispatch(actions.setSelectedCategory(null))
    }
  }, [categories, dispatch, categoryType])

  const onHeaderItemClick = (category: any) => {
    const categoryType = category.name.replace(/\s+/g, '-').toLowerCase()
    navigate(`/services/${categoryType}`)
    setSearchValue('')
    dispatch(resetSearchInfoRequest())
    dispatch(actions.setSelectedCategory(category))
  }

  const onHeaderWatchListClick = () => {
    setSearchValue('')
    navigate(`/watch-later`)
  }

  const handleSearchData = (e: any) => {
    setSearchValue(e.target.value)
    debouncedGetSearchData(e.target.value)
  }

  const debouncedGetSearchData = useMemo(
    () =>
      _.debounce(value => {
        if (value !== '' && value?.length > 2) {
          dispatch(getGlobalSearchRequest({ searchValue: value }))
        } else if (value === '') {
          dispatch(resetSearchInfoRequest())
        }
      }, 1000),
    [dispatch],
  )

  const categoriesApi = getApiLoaderState('/home/categories')
  const isWatchListActive = ['/watch-later'].some(key =>
    location.pathname.includes(key),
  )
  return categoriesApi.loading ? (
    <Loader />
  ) : (
    <div
      className={`header-container ${hasScrolled ? 'scrolled' : ''} ${
        isSolidBg ? 'solidBg' : 'gradientBg'
      } `}
    >
      <div className={`header-style ${isSolidBg ? 'gradientBg' : ''}`}>
        <div className="logo-layout">
          <SwaraLogo
            className="logo cursor-pointer"
            onClick={() => {
              setSearchValue('')
              dispatch(resetSearchInfoRequest())
              navigate('/')
            }}
          />
        </div>
        <div className="header-category-list">
          {categories.map((category, index) => {
            const isActive =
              selectedCategory?._id === category._id ? 'Active' : ''
            return (
              <div
                key={index}
                id={category._id}
                onClick={() => onHeaderItemClick(category)}
                className={`header-category-item ${isActive ? 'active' : ''}`}
              >
                {category.name}
              </div>
            )
          })}

          {isAuthenticated && categories?.length > 0 ? (
            <div
              onClick={() => onHeaderWatchListClick()}
              className={`header-category-item ${
                isWatchListActive ? 'active' : ''
              }`}
            >
              Watch list
            </div>
          ) : null}
        </div>
        <div className="header-right-container">
          <input
            className="search-input"
            value={searchValue}
            onChange={e => handleSearchData(e)}
          />
          {isAuthenticated ? (
            <img
              className="profile-header"
              src={userInfo?.profileImageUrl || UserLogo}
              onClick={() => navigate('/profile')}
            />
          ) : (
            // <Link to="/">
            //   <Button onClick={() => dispatch(logoutRequest({ navigate }))}>
            //     Sign Out
            //   </Button>
            // </Link>
            <Link to="/login">
              <Button>Sign In</Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default App
