import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { Section } from 'store/category/types'
import { createSlice } from 'utils/@reduxjs/toolkit'
import { CommonState } from './types'

const getSectionWiseDataRequest = createAction<{
  skip: number
  limit: number
  section?: Section
}>('common/getSectionWiseDataRequest')

const getVideoFileInfoRequest = createAction<{
  type: string
  videoId: string
}>('common/getVideoFileInfoRequest')

const getGlobalSearchRequest = createAction<{
  searchValue: string
}>('common/getGlobalSearchRequest')
const resetSearchInfoRequest = createAction('common/resetSearchInfoRequest')
const captureVideoActivityRequest = createAction<{
  videoId: string
  lastWatchedTime: number
  type: string
  timeSpent?: any
  additionalData: any
}>('common/captureVideoActivityRequest')

const captureAudioActivityRequest = createAction<{
  albumId: string
  audioTrackId: string
  lastWatchedTime: number
  timeSpent?: any
  additionalData?: any
}>('common/captureAudioActivityRequest')

const getContinueWatchingRequest = createAction('common/continueWatching')
const getRadioEventsRequest = createAction('common/getRadioEventsRequest')
const getWeeklyRadioEventsRequest = createAction(
  'common/getWeeklyRadioEventsRequest',
)
const getRadioHostsRequest = createAction('common/getRadioHostsRequest')
const getRadioSponsorsRequest = createAction('common/getRadioSponsorsRequest')
const registerForRadioShowEventRequest = createAction<any>(
  'common/registerForRadioShowEventRequest',
)
const sendFeedback = createAction<{ data: any; navigate: any }>(
  'common/sendFeedback',
)

export const initialState: CommonState = {
  networkCall: {
    pendingRequests: [],
    loading: false,
    error: null,
  },
  continueWatching: { data: [], isMoreData: true },
  videoInfo: {},
  sectionsData: {},
  searchData: {},
  searchObject: {},
  radioEventsData: { data: [], isMoreData: true },
  weeklyRadioEventsData: {},
  radioSponsorsData: { data: [], isMoreData: true },
  radioHostsData: { data: [], isMoreData: true },
}
const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setNetworkCallRequestConfig(
      state: CommonState,
      action: PayloadAction<CommonState['networkCall']>,
    ) {
      state.networkCall = {
        ...state.networkCall,
        ...action.payload,
      }
    },
    setNetworkCallResponseConfig(
      state: CommonState,
      action: PayloadAction<CommonState['networkCall']>,
    ) {
      state.networkCall = {
        ...state.networkCall,
        ...action.payload,
      }
    },
    setVideoInfo(state: CommonState, action: PayloadAction<any>) {
      state.videoInfo = action.payload
    },
    setSectionsData(state: CommonState, action: PayloadAction<any>) {
      if (action.payload) {
        const { payload, sectionDetails } = action.payload
        state.sectionsData[sectionDetails.code] = {
          data: payload.data,
          isMoreData: payload.isMoreData,
          loading: payload.loading,
        }
      } else state.sectionsData = {}
    },
    setSectionsLoading(state: CommonState, action: PayloadAction<any>) {
      const { loading, sectionDetails } = action.payload
      state.sectionsData[sectionDetails.code] = {
        ...state.sectionsData[sectionDetails.code],
        loading,
      }
    },
    setSectionIsMoreData(state: CommonState, action: PayloadAction<any>) {
      const { isMoreData, sectionDetails } = action.payload
      state.sectionsData[sectionDetails.code] = {
        ...state.sectionsData[sectionDetails.code],
        isMoreData,
      }
    },
    setSearchData(state: CommonState, action: PayloadAction<any>) {
      state.searchData = action.payload
    },
    setSearchObject(state: CommonState, action: PayloadAction<any>) {
      state.searchObject = action.payload
    },
    setContinueWatching(state: CommonState, action: PayloadAction<any>) {
      state.continueWatching = {
        ...state.continueWatching,
        data: action.payload,
      }
    },
    setRadioEventsData(state: CommonState, action: PayloadAction<any>) {
      state.radioEventsData = {
        ...state.radioEventsData,
        data: action.payload,
      }
    },
    setWeeklyRadioEventsData(state: CommonState, action: PayloadAction<any>) {
      state.weeklyRadioEventsData = {
        ...state.weeklyRadioEventsData,
        ...action.payload,
      }
    },
    setRadioHostsData(state: CommonState, action: PayloadAction<any>) {
      state.radioHostsData = {
        ...state.radioHostsData,
        data: action.payload,
      }
    },
    setRadioSponsorsData(state: CommonState, action: PayloadAction<any>) {
      state.radioSponsorsData = {
        ...state.radioSponsorsData,
        data: action.payload,
      }
    },
  },
})

export {
  getSectionWiseDataRequest,
  getVideoFileInfoRequest,
  getGlobalSearchRequest,
  resetSearchInfoRequest,
  captureVideoActivityRequest,
  captureAudioActivityRequest,
  getContinueWatchingRequest,
  getRadioEventsRequest,
  getRadioSponsorsRequest,
  getRadioHostsRequest,
  registerForRadioShowEventRequest,
  getWeeklyRadioEventsRequest,
  sendFeedback,
}
export const { actions, reducer, name: sliceKey } = commonSlice
