/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'
import { all, fork } from 'redux-saga/effects'
import { InjectedReducersType } from 'utils/types/injector-typings'
import { authSaga } from 'store/auth/saga'
import { categorySaga } from 'store/category/saga'
import { reducer as auth } from 'store/auth/slice'
import { reducer as user } from 'store/user/slice'
import { reducer as category } from 'store/category/slice'
import { reducer as common } from 'store/common/slice'
import { reducer as movies } from 'store/movies/slice'
import { moviesSaga } from './movies/saga'
import { reducer as series } from 'store/series/slice'
import { reducer as magazines } from 'store/magazines/slice'
import { seriesSaga } from './series/saga'
import { commonSaga } from './common/sagas'
import { magazinesSaga } from './magazines/saga'
import { reducer as music } from 'store/music/slice'
import { musicSaga } from './music/saga'
import { reducer as watchLater } from 'store/watch-later/slice'
import { watchLaterSaga } from './watch-later/saga'
import { userSaga } from './user/saga'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error

  return combineReducers({
    auth,
    user,
    category,
    common,
    movies,
    music,
    series,
    magazines,
    watchLater,
    ...injectedReducers,
  })
}
export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(commonSaga),
    fork(categorySaga),
    fork(moviesSaga),
    fork(seriesSaga),
    fork(magazinesSaga),
    fork(musicSaga),
    fork(watchLaterSaga),
  ])
}
