import axios, { AxiosInstance } from 'axios'
import { radioCmsConfig } from 'api/variables'
import { toast } from 'react-toastify'
import storeInstance from 'store/configureStore'
import { actions } from 'store/common/slice'
import { resetMusicTrackDetailsRequest } from 'store/music/slice'
import { actions as musicSliceActions } from 'store/music/slice'
import { actions as authActions } from 'store/auth/slice'

import { localStorageRemoveMusicData } from 'utils/helpers/functions'
import history from 'utils/history'

const baseAxios: AxiosInstance = axios.create({
  baseURL: `${radioCmsConfig.baseUrl}`,
  timeout: 15000,
})

function getPendingRequests(response: any) {
  const completedUrl = response?.config?.url
  const state = storeInstance?.store?.getState()
  const pendingRequests = [
    ...(state?.common?.networkCall?.pendingRequests || []),
  ]
  const reqToUpdateIdx = pendingRequests.findIndex(
    i => i.requestUrl === completedUrl,
  )
  const updatedPendingRequests = [...pendingRequests]
  updatedPendingRequests.splice(reqToUpdateIdx, 1)
  return updatedPendingRequests
}

baseAxios.interceptors.request.use(
  function (request: any) {
    const state = storeInstance?.store?.getState()
    const pendingRequests = [
      ...(state?.common?.networkCall?.pendingRequests || []),
    ].filter(i => i.requestUrl !== request.url) // remove the existing request with the same requestUrl

    if (request.url)
      pendingRequests.push({
        loading: true,
        error: null,
        requestUrl: request.url,
        status: 'pending',
      })
    storeInstance.store.dispatch(
      actions.setNetworkCallRequestConfig({ loading: true, pendingRequests }),
    )

    if (localStorage.getItem('TOKEN')) {
      request.headers['X-Api-Key'] = `${localStorage.getItem('TOKEN')}`
    }

    return request
  },
  function (error) {
    console.log(error)
    throw error
  },
)

baseAxios.interceptors.response.use(
  function (response: any) {
    const updatedPendingRequests = getPendingRequests(response)
    if (response.status === 200 || response.status === 201) {
      storeInstance.store.dispatch(
        actions.setNetworkCallResponseConfig({
          loading: false,
          error: null,
          pendingRequests: updatedPendingRequests,
        }),
      )
      return response
    }

    throw response
  },
  function (error) {
    if (error?.code === 'ECONNABORTED') {
      return
    }
    // Session cleared whenever user session is expired
    if (
      error?.response?.data?.errorMessage ===
      'session expired, please login again !!'
    ) {
      localStorage.removeItem('TOKEN')
      localStorage.removeItem('USER_DATA')
      window.location.href = ''
    }
    // if (error.response?.status === 400) window.location.href = '/login'

    const updatedPendingRequests = getPendingRequests(error)
    storeInstance.store.dispatch(
      actions.setNetworkCallResponseConfig({
        loading: false,
        error: null,
        pendingRequests: updatedPendingRequests,
      }),
    )

    const errorMessage: string =
      error?.response?.data?.errorMessage ||
      'Sorry, the server is busy. Please try again later'
    if (
      errorMessage === 'session expired, please login again' ||
      errorMessage === 'Access Token has been revoked'
    ) {
      storeInstance.store.dispatch(resetMusicTrackDetailsRequest())
      storeInstance.store.dispatch(
        musicSliceActions.setAudioPlayerActive(false),
      )
      localStorageRemoveMusicData()
      history.push('/')
      localStorage.removeItem('TOKEN')
      localStorage.removeItem('USER_DATA')
      storeInstance.store.dispatch(authActions.loggedOut())
    } else if (errorMessage) {
      toast.error(errorMessage)
    }
    storeInstance.store.dispatch(
      actions.setNetworkCallResponseConfig({
        loading: false,
      }),
    )
    throw error
  },
)

export { baseAxios }
