type Config = {
  baseUrl: string
  serverName: string
}

const config: Config = {
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
  serverName: `Swara App`,
}

const radioCmsConfig: Config = {
  baseUrl: `${process.env.REACT_APP_CMS_API_BASE_URL}`,
  serverName: `Swara App`,
}

const swaraAdminRadioCmsBasePath = `${process.env.REACT_APP_RADIO_MANAGEMENT_API_BASE_PATH}`

const apiEndPoints = {
  auth: {
    login: `/auth/login`,
    logout: `auth/logout`,
    register: `/auth/signup`,
    verifyRegistration: `/auth/signup/verify`,
    forgotPassword: `/auth/signup/forgot-password`,
    resendVerificationCode: `/auth/signup/resend-code`,
    verifyForgotPassword: `/auth/signup/forgot-password/verify`,
    verifyFederatedLogin: `/auth/federatedLogin`,
  },
  categories: {
    getCategories: `home/categories`,
    getSectionsByCategory: (catergoryId: string) =>
      `/home/sections/${catergoryId}`,
    getBannersByCategory: (categoryId: string) => `/banners/${categoryId}`,
  },
  movies: {
    getLatestMovies: `videos/movies/latest`,
    getMovieInfo: (movieId: string) => `videos/movies/${movieId}`,
    getSimilarMovies: (
      genre: string,
      skip: number,
      limit: number,
      movieId: number,
    ) =>
      `/videos/movies/similar?genre=${genre}&skip=${skip}&limit=${limit}&movieId=${movieId}`,
  },
  music: {
    getTrendingSongs: (skip: number, limit: number) =>
      `/trending-songs?skip=${skip}&limit=${limit}`,
    getAlbumTracksDetails: (albumId: number) =>
      `/audios/albums/${albumId}/audio-tracks`,
    getAlbumInfo: (albumId: number) => `/audios/album/${albumId}`,
    captureAudioActivity: `/capture-audio-activity`,
    getLastPlayedTracks: (type: string) => `/audio-activity?type=${type}`,
  },
  series: {
    getLatestWebSeriesOrTalkShowsInfo: (webSeriesType: string) =>
      `/videos/web-series/latest?webSeriesType=${webSeriesType}`,
    getSimilarWebSeriesOrTalkShows: (
      webSeriesType: string,
      genre: string,
      webSeriesId: string,
    ) =>
      `/videos/web-series/similar?webSeriesType=${webSeriesType}&genre=${genre}&webSeriesId=${webSeriesId}`,
    getWebSeriesSeasonsAndEpisodes: (webSeriesId: string) =>
      `/videos/web-series/${webSeriesId}/seasons`,
    getEpisodesFromWebSeriesSeason: (webSeriesId: string, seasonId: string) =>
      `/videos/web-series/${webSeriesId}/seasons/${seasonId}/episodes`,
    getWebSeriesInfo: (webSeriesId: string) =>
      `/videos/web-series/${webSeriesId}`,
    getEpisodeInfo: (
      webSeriesId: string,
      seasonId: string,
      episodeId: string,
    ) =>
      `/videos/web-series/${webSeriesId}/seasons/${seasonId}/episodes/${episodeId}`,
  },
  magazines: {
    getMagazineInfo: (magazineId: string) => `/magazines/${magazineId}`,
  },
  userAnalytics: {
    captureVideoActivity: `/capture-video-activity`,
    captureAudioActivity: `/capture-audio-activity`,
    continueWatching: `/continue-watching`,
  },
  videoInfo: (videoId: string) => `/videos/${videoId}`,
  watchLater: {
    addContentToWatchLater: '/watch-later',
    removeContentFromWatchLater: '/watch-later/remove',
    getWatchLaterInfo: '/watch-later/info',
    getWatchLaterContentTypes: '/watch-later/content-types',
  },
  search: {
    getSearchContent: '/content/search',
  },
  user: {
    getUserInfo: '/users/info',
    updateUserInfo: '/users',
    changePassword: '/auth/users/change-password',
    listPreferences: '/preferences',
    userPreferences: '/auth/user/preferences',
    editUserPreferences: (preferenceId: string) =>
      `/auth/user/preferences/${preferenceId}`,
  },
  radio: {
    getWeeklyRadioEvents: `/${swaraAdminRadioCmsBasePath}/web/radio-shows`,
    getWeeklyRegisterableRadioEvents: `/${swaraAdminRadioCmsBasePath}/web/radio-shows/available`,
    getRadioHosts: `/${swaraAdminRadioCmsBasePath}/web/radio-hosts`,
    getRadioSponsors: `/${swaraAdminRadioCmsBasePath}/web/sponsors`,
    registerForRadioShowEvents: (
      radioShowId: string,
      radioShowEventId: string,
    ) =>
      `/${swaraAdminRadioCmsBasePath}/radio-shows/${radioShowId}/events/${radioShowEventId}/register`,
  },
  feedback: {
    feedback: `/feedback`,
  },
}

export { config, apiEndPoints, radioCmsConfig, swaraAdminRadioCmsBasePath }
