import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  actions,
  getAlbumInfoRequest,
  getAlbumTrackDetailsRequest,
  getTrendingAlbumRequest,
  captureAudioActivityRequest,
  getLastPlayedAudioTracksRequest,
  resetMusicTrackDetailsRequest,
} from './slice'

function* getTrendingAlbum({ payload: { skip, limit } }: any): any {
  try {
    yield put(actions.setTrendingAlbumData([]))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.music.getTrendingSongs(skip, limit),
    )
    if (response.data.length)
      yield put(actions.setTrendingAlbumData(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* getAlbumTrackDetails({ payload: { albumId } }: any): any {
  try {
    yield put(actions.setAlbumTracksDetails([]))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.music.getAlbumTracksDetails(albumId),
    )
    if (response.data.length)
      yield put(actions.setAlbumTracksDetails(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* getAlbumDetails({ payload: { albumId } }: any): any {
  try {
    yield put(actions.setAlbumInfo({}))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.music.getAlbumInfo(albumId),
    )
    if (response.data) yield put(actions.setAlbumInfo(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* captureAudioActivity({ payload }: any): any {
  try {
    yield call(baseAxios.post, apiEndPoints.music.captureAudioActivity, payload)
  } catch (err) {
    console.log(err)
  }
}

function* getLastPlayedAudioTracks({ payload: { type } }: any): any {
  try {
    yield put(actions.setRecentPlayedTracks([]))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.music.getLastPlayedTracks(type),
    )
    if (response.data) yield put(actions.setRecentPlayedTracks(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* resetMusicTrackDetails(): any {
  yield put(actions.setCurrentTrackDetails({}))
  yield put(actions.setTrackList([]))
  yield put(actions.setRecentPlayedTracks({}))
  yield put(actions.setTrackPlaying(false))
}

export function* musicSaga(): any {
  yield all([yield takeLatest(getTrendingAlbumRequest, getTrendingAlbum)])
  yield all([
    yield takeLatest(getAlbumTrackDetailsRequest, getAlbumTrackDetails),
  ])
  yield all([yield takeLatest(getAlbumInfoRequest, getAlbumDetails)])
  yield all([
    yield takeLatest(captureAudioActivityRequest, captureAudioActivity),
  ])
  yield all([
    yield takeLatest(getLastPlayedAudioTracksRequest, getLastPlayedAudioTracks),
  ])
  yield all([
    yield takeLatest(resetMusicTrackDetailsRequest, resetMusicTrackDetails),
  ])
}
