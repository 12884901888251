import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { all, call, takeLatest, put } from 'redux-saga/effects'
import {
  addContentToWatchLaterRequest,
  removeContentFromWatchLaterRequest,
  getWatchLaterInfoRequest,
  getWatchLaterContentTypesInfoRequest,
} from './slice'

import { actions } from '../movies/slice'
import { actions as seriesActions } from '../series/slice'
import { actions as watchLaterActions } from './slice'
import { watchLaterContentType } from 'utils/helpers/constants'
import _ from 'lodash'
import { toast } from 'react-toastify'

function* addContentToWatchLater({ payload }: any): any {
  try {
    yield call(
      baseAxios.post,
      apiEndPoints.watchLater.addContentToWatchLater,
      payload,
    )
    if (
      payload.type === watchLaterContentType.WebSeries ||
      payload.type === watchLaterContentType.Interviews ||
      payload.type === watchLaterContentType.TalkShows
    ) {
      yield put(seriesActions.watchWebSeriesLaterSuccess())
    } else {
      yield put(actions.watchMovieLaterSuccess())
    }
    toast.success('Added to Watch List Successfully!')
  } catch (err) {
    console.log(err)
  }
}

function* removeContentToWatchLater({ payload }: any): any {
  try {
    yield call(
      baseAxios.post,
      apiEndPoints.watchLater.removeContentFromWatchLater,
      payload,
    )
    if (
      payload.type === watchLaterContentType.WebSeries ||
      payload.type === watchLaterContentType.Interviews ||
      payload.type === watchLaterContentType.TalkShows
    ) {
      yield put(seriesActions.removeWebSeriesFromWatchLaterSuccess())
    } else {
      yield put(actions.removeMovieFromWatchLaterSuccess())
    }
  } catch (err) {
    console.log(err)
  }
}

function* getWatchLaterInfo({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.watchLater.getWatchLaterInfo,
      payload,
    )
    if (response.data) {
      const data = _.groupBy(response.data, dt => dt.type)
      yield put(watchLaterActions.setWatchLaterData(data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* getWatchLaterContentTypes({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.watchLater.getWatchLaterContentTypes,
      payload,
    )
    if (response.data) {
      yield put(watchLaterActions.setWatchLaterContentTypes(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* watchLaterSaga(): any {
  yield all([
    yield takeLatest(addContentToWatchLaterRequest, addContentToWatchLater),
    yield takeLatest(
      removeContentFromWatchLaterRequest,
      removeContentToWatchLater,
    ),
    yield takeLatest(getWatchLaterInfoRequest, getWatchLaterInfo),
    yield takeLatest(
      getWatchLaterContentTypesInfoRequest,
      getWatchLaterContentTypes,
    ),
  ])
}
