import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MusicState } from './types'

const getTrendingAlbumRequest = createAction<{
  skip: number
  limit: number
}>('music/getTrendingAlbumRequest')

const getAlbumTrackDetailsRequest = createAction<{ albumId: any }>(
  'music/getAlbumTrackDetailsRequest',
)

const getAlbumInfoRequest = createAction<{ albumId: any }>(
  'music/getAlbumInfoRequest',
)

const captureAudioActivityRequest = createAction<any>(
  'music/captureAudioActivityRequest',
)

const getLastPlayedAudioTracksRequest = createAction<any>(
  'music/getLastPlayedAudioTracks',
)

const resetMusicTrackDetailsRequest = createAction(
  'music/resetMusicTrackDetailsRequest',
)

export const initialState: MusicState = {
  trendingAlbumData: [],
  albumTracksDetails: [],
  albumInfo: {},
  currentTrackDetails: {},
  trackList: [],
  recentPlayedTracks: {},
  audioPlayerActive: false,
  trackPlaying: false,
  radioPlaying: false,
  videoPlaying: false,
  isMoreData: true,
}

const musicSlice = createSlice({
  name: 'music',
  initialState,
  reducers: {
    setTrendingAlbumData(state, action: PayloadAction<any>) {
      state.trendingAlbumData = [...action.payload]
    },
    setAlbumTracksDetails(state, action: PayloadAction<any>) {
      state.albumTracksDetails = action.payload
    },
    setAlbumInfo(state, action: PayloadAction<any>) {
      state.albumInfo = action.payload
    },
    setCurrentTrackDetails(state, action: PayloadAction<any>) {
      state.currentTrackDetails = action.payload
    },
    setTrackList(state, action: PayloadAction<any>) {
      state.trackList = action.payload
    },
    setTrackPlaying(state, action: PayloadAction<any>) {
      state.trackPlaying = action.payload
    },
    setRadioPlaying(state, action: PayloadAction<any>) {
      state.radioPlaying = action.payload
    },
    setAudioPlayerActive(state, action: PayloadAction<any>) {
      state.audioPlayerActive = action.payload
    },
    setRecentPlayedTracks(state, action: PayloadAction<any>) {
      state.recentPlayedTracks = action.payload
    },
    setVideoPlaying(state, action: PayloadAction<any>) {
      state.videoPlaying = action.payload
    },
  },
})

export {
  getTrendingAlbumRequest,
  getAlbumInfoRequest,
  getAlbumTrackDetailsRequest,
  captureAudioActivityRequest,
  getLastPlayedAudioTracksRequest,
  resetMusicTrackDetailsRequest,
}
export const { actions, reducer, name: sliceKey } = musicSlice
