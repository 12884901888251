import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions } from 'store/common/slice'
import { VideoTypes } from 'utils/helpers/constants'
import {
  StyledContinueCard,
  StyledContinueCardLink,
  // StyledGradientDiv,
  StyledProgress,
  StyledThumbnail,
} from './StyledComponents'

interface ContinueCardProps {
  data: any
}

const Index = ({ data }: ContinueCardProps) => {
  const { _id, type, percentage, lastWatchedTime, additionalData } = data
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onContinueCardClick = () => {
    dispatch(
      actions.setVideoInfo({ ...additionalData.videoInfo, lastWatchedTime }),
    )
    if (type === VideoTypes.MOVIES)
      navigate(`/movies/${additionalData.movieInfo._id}/watch`)
    else if (type === VideoTypes.WEB_SERIES)
      navigate(`/series/${type}/${additionalData.webSeriesInfo._id}/watch`)
  }
  return (
    <StyledContinueCard
      className="continue-card"
      key={_id}
      onClick={onContinueCardClick}
    >
      <StyledContinueCardLink>
        <StyledThumbnail>
          <img
            src={
              additionalData[
                `${type === VideoTypes.MOVIES ? 'movieInfo' : 'episodeInfo'}`
              ]['titleImageUrl']
            }
            loading="lazy"
            alt={data?.name}
          />
          {/* <StyledGradientDiv /> */}

          <StyledProgress
            value={percentage >= 100 ? 100 : percentage <= 0 ? 0 : percentage}
          />
        </StyledThumbnail>
      </StyledContinueCardLink>
    </StyledContinueCard>
  )
}

export default Index
