import React, { useMemo } from 'react'
import { CardTypes, ImageOrginSource } from 'utils/helpers/constants'
import {
  // StyledGradientDiv,
  StyledGenericCard,
  StyledGenericCardLink,
  StyledRegisterTextDiv,
  StyledThumbnail,
  StyledThumbnailTitle,
} from './StyledComponents'
import { getApiLoaderState, getImageURL } from 'utils/helpers/functions'
import { StyledBannerButton } from '../DetailsBanner/StyledComponents'
import moment from 'moment'
import { swaraAdminRadioCmsBasePath } from 'api/variables'
interface GenericCardProps {
  data: any
  imageUrlKey?: string
  cardType: CardTypes
  showTitle?: boolean
  viewAll?: boolean
  onHandleCardClick?: any
  className?: string
  sectionType?: string
  onRadioRegisterClick?: any
  showRadioEventRegisterButton?: any
}

const Index: React.FC<GenericCardProps> = ({
  data,
  imageUrlKey = 'titleImageUrl',
  showTitle = false,
  cardType,
  onHandleCardClick,
  className = '',
  sectionType,
  onRadioRegisterClick,
  showRadioEventRegisterButton = true,
}) => {
  const { _id, name, imageSource } = data
  const { loading } = getApiLoaderState(
    `/${swaraAdminRadioCmsBasePath}/radio-shows/${data?.radioShowId}/events/${data?._id}/register`,
  )

  const cardTypeCss = useMemo(() => {
    let css: any
    /* Movie card is considered by default in Styled components and we can override
     other cards width and heigt from here */
    switch (cardType) {
      // TODO: Add css for every case as we build screens
      // Note: Remove height variables for StyledThumbnail and enable paddinBottom for StyledGenericCard when image sizes are set on cloud.
      case CardTypes.EPISODE:
        css = {
          widthFactor: 5.5,
          heightfactor: 0.56471,
          height: '170px',
        }
        break
      case CardTypes.MOVIE_RECOMMENDATIONS:
        css = { widthFactor: 6.5, width: '220px', height: '130px' }
        break
      case CardTypes.MUSIC:
        css = { width: '236px' }
        break
      case CardTypes.LATEST_NEWS:
        css = ``
        break
      case CardTypes.RELATED_NEWS:
        css = ``
        break
      case CardTypes.PODCASTS:
        css = ``
        break
      case CardTypes.MUSIC_TOP_PLAYLIST:
        css = ``
        break
      case CardTypes.MUSIC_PLAYLIST_ICON:
        css = ``
        break
      case CardTypes.ALBUM:
        css = {
          widthFactor: 5,
          heightfactor: 0.56471,
          width: '320px',
          height: '240px',
        }
        break
      case CardTypes.SIMILAR_SONG:
        css = { widthFactor: 6.5, heightfactor: 0.56471, height: '200px' }
        break
      case CardTypes.REGISTER_CARD:
        css = { widthFactor: 16.5, heightfactor: 0.56471, height: '300px' }
        break
      default:
        css = ``
        break
    }
    return css
  }, [cardType])

  const formatDateTimeRange = (startDate: any, endDate: any) => {
    const formattedStartDate = moment(startDate).format('Do MMMM YYYY')
    const formattedStartTime = moment(startDate).format('hh:mm')
    const formattedEndTime = moment(endDate).format('hh:mm A')
    return [formattedStartDate, `${formattedStartTime} - ${formattedEndTime}`]
  }

  const isRadioEventRegister = sectionType === 'radio_events_register'
  return (
    <StyledGenericCard
      key={_id}
      className={className ? className : 'generic-card'}
      showTitle={showTitle}
      css={cardTypeCss}
      widthFactor={cardTypeCss.widthFactor || 8.5}
      // heightFactor={cardTypeCss.heightfactor || 1.32821}
    >
      <StyledGenericCardLink
        onClick={() => {
          onHandleCardClick(_id, data)
        }}
        css={{ background: '#393b4d' }}
      >
        <StyledThumbnail css={cardTypeCss}>
          {imageSource === ImageOrginSource.ImageToolKit ? (
            <img
              src={getImageURL(
                data.titleImageDomainPath,
                data.titleImageRelativePath,
                '100',
                '100',
              )}
              loading="lazy"
              alt={data?.name}
            />
          ) : (
            <img
              src={
                data[imageUrlKey] ||
                data['titleImageUrl'] ||
                data['imageUrl'] ||
                data['thumbnailImageUrl'] ||
                data['albumThumbnailImageUrl'] ||
                data['profileImageUrl']
              }
              loading="lazy"
              alt={data?.name}
            />
          )}
        </StyledThumbnail>

        {isRadioEventRegister && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: showRadioEventRegisterButton
                ? 'space-between'
                : 'center',
              height: 'auto',
              backgroundColor: '#393b4d',
            }}
          >
            <div>
              <StyledRegisterTextDiv>
                {' '}
                {formatDateTimeRange(data?.startDate, data?.endDate)[0]}{' '}
              </StyledRegisterTextDiv>
              <StyledRegisterTextDiv>
                {formatDateTimeRange(data?.startDate, data?.endDate)[1]}
              </StyledRegisterTextDiv>
            </div>
            {showRadioEventRegisterButton && (
              <StyledBannerButton
                className="my-4"
                bgColor="#C00A2E"
                width={'80%'}
                color="#FFF"
                borderColor="transparent"
                onClick={() =>
                  onRadioRegisterClick(data?._id, data?.radioShowId)
                }
              >
                {loading ? (
                  <span className="indicator-progress">
                    Wait...
                    <span className="spinner-border spinner-border-sm align-middle ml-2"></span>
                  </span>
                ) : (
                  <span className="indicator-label">Register</span>
                )}
              </StyledBannerButton>
            )}
          </div>
        )}
        {showTitle && <StyledThumbnailTitle>{name}</StyledThumbnailTitle>}
      </StyledGenericCardLink>
    </StyledGenericCard>
  )
}

export default Index
