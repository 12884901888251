import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CategoriesState } from './types'

const categoriesRequest = createAction('category/categoriesRequest')
const getSectionsByCategoryRequest = createAction<{ categoryId: string }>(
  'category/getSectionsByCategoryRequest',
)
const getBannersBycategoryRequest = createAction<{
  categoryId: string
}>('auth/getBannersBycategoryRequest')

export const initialState: CategoriesState = {
  categories: [],
  selectedCategory: null,
  sectionsByCategory: {},
  bannersByCategory: {},
}
const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    onGetCategoriesSuccess(state, action: PayloadAction<any>) {
      state.categories = action.payload
    },
    setSelectedCategory(state, action: PayloadAction<any>) {
      state.selectedCategory = action.payload
    },
    setSectionsByCategory(state, action: PayloadAction<any>) {
      if (state.selectedCategory)
        state.sectionsByCategory[state.selectedCategory.name] = action.payload
    },
    onGetBannerByCategorySuccess(state, action: PayloadAction<any>) {
      if (state.selectedCategory)
        state.bannersByCategory[state.selectedCategory.name] = action.payload
    },
    resetAllSectionData(state) {
      state.sectionsByCategory = {
        ...state.sectionsByCategory,
        All: [],
      }
    },
  },
})

export {
  categoriesRequest,
  getSectionsByCategoryRequest,
  getBannersBycategoryRequest,
}
export const { actions, reducer, name: sliceKey } = categorySlice
