import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import App from './App'
import { HelmetProvider } from 'react-helmet-async'
import reportWebVitals from './reportWebVitals'
import storeInstance from './store/configureStore'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { datadogLogs } from '@datadog/browser-logs'
// import { datadogRum } from '@datadog/browser-rum'

// Css files
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/scss/app.scss'
// eslint-disable-next-line
import * as types from 'styled-components/cssprop' //  This line helps in adding css prop on html elements in typescript.
import { appTheme } from 'styles/theme'

const { store } = storeInstance

const mountNode = document.getElementById('root') as HTMLElement

if (process.env.REACT_APP_ENABLE_DATA_DOG == 'true') {
  // datadog :: Rum
  // datadogRum.init({
  //   applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
  //   clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  //   site: process.env.REACT_APP_DATADOG_SITE,
  //   service: 'swara-web-ui',
  //   env: process.env.REACT_APP_ENV,
  //   // version: '1.0.0',
  //   sessionSampleRate: 100,
  //   sessionReplaySampleRate: 20,
  //   trackUserInteractions: true,
  //   trackResources: true,
  //   trackLongTasks: true,
  //   defaultPrivacyLevel: 'mask-user-input',
  // })

  // datadogRum.startSessionReplayRecording()

  // datadog :: Logs
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: process.env.REACT_APP_DATADOG_SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'SWARA-WEB-UI' + '-' + process.env.REACT_APP_ENV,
  })
}

const ConnectedMainApp = (
  <Provider store={store}>
    <ThemeProvider theme={appTheme}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ThemeProvider>
  </Provider>
)

render(ConnectedMainApp, mountNode)

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./App'], () => {
    unmountComponentAtNode(mountNode)
    const { App } = require('./App')
    render(App, mountNode)
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
