import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  categoriesRequest,
  actions,
  getBannersBycategoryRequest,
  getSectionsByCategoryRequest,
} from './slice'
import { actions as commonActions } from '../common/slice'

function* getCategories(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.categories.getCategories,
    )

    if (response.data) {
      response.data = response.data.map((res: { name: string }) => {
        return {
          ...res,
          categoryName: res.name.replace(/\s+/g, '-').toLowerCase(),
        }
      })
      yield put(actions.onGetCategoriesSuccess(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* getBannersByCategory({ payload: { categoryId } }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.categories.getBannersByCategory(categoryId),
    )

    if (response.data) {
      yield put(actions.onGetBannerByCategorySuccess(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* getSectionsByCategory({ payload: { categoryId } }: any): any {
  try {
    yield put(actions.setSectionsByCategory([]))
    yield put(commonActions.setSectionsData(null))
    const response = yield call(
      baseAxios.get,
      apiEndPoints.categories.getSectionsByCategory(categoryId),
      { params: { limit: 20, skip: 0 } },
    )

    if (response.data) {
      yield put(actions.setSectionsByCategory(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* categorySaga(): any {
  yield all([
    yield takeLatest(categoriesRequest, getCategories),
    yield takeLatest(getSectionsByCategoryRequest, getSectionsByCategory),
    yield takeLatest(getBannersBycategoryRequest, getBannersByCategory),
  ])
}
