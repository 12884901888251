import { useSelector } from 'react-redux'
import { selectCommon } from 'store/common/selector'

const getApiLoaderState = (apiString: string) => {
  const {
    networkCall: { pendingRequests },
  } = useSelector(selectCommon)
  return (
    pendingRequests?.filter(req =>
      req.requestUrl?.includes(apiString),
    )?.[0] || { loading: false }
  )
}

const getImageURL = (
  imageDomainPath: string,
  imageRelativePath: string,
  width: string,
  height: string,
) => {
  return `${imageDomainPath}${imageRelativePath}?tr=w-${width},h-${height}`
}

const localStorageSaveItem = (key: string, item: any) => {
  localStorage.setItem(key, JSON.stringify(item))
}

const localStorageGetItem = (key: string) => {
  const data: any = localStorage.getItem(key)
  return data && data != 'undefined' ? JSON.parse(data) : null
}

const localStorageRemoveItem = (key: string) => {
  localStorage.removeItem(key)
}

const localStorageRemoveMusicData = () => {
  localStorage.removeItem('currentTrackDetails')
  localStorage.removeItem('trackList')
  localStorage.removeItem('audioPlayedTime')
}

export {
  getApiLoaderState,
  getImageURL,
  localStorageSaveItem,
  localStorageGetItem,
  localStorageRemoveItem,
  localStorageRemoveMusicData,
}
