import styled from 'styled-components'

export const StyledProfileContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  padding: 100px 40px;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 140px 0 40px;
    margin-left: 0;
    margin-right: 0;
  }
`

export const StyledMenuNav = styled.div`
  margin-right: 50px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
  @media (max-width: 576px) {
    width: 35%;
    /* padding: 40px 0; */
  }
`

export const StyledMenuContent = styled.div`
  /* padding: 20px 10px; */
  @media (max-width: 576px) {
    width: 60%;
  }
`

export const StyledChips = styled.div<{
  isSelected: boolean
  isCategory?: boolean
}>`
  width: auto;
  padding: 8px 20px;
  margin: 10px 10px 0;
  background: ${props => (props.isSelected ? '#c00a2e' : 'none')};
  color: ${props => (props.isSelected ? '#ffffff' : '#c00a2e')};
  border: 2px solid #c00a2e;
  border-radius: 100px;
  font-weight: ${props => (props.isSelected ? 'normal' : 600)};
  cursor: pointer;
  white-space: nowrap;
  @media (max-width: 1024px) {
    ${props =>
      props.isCategory
        ? `background: ${
            props.isSelected ? '#c00a2e' : '#393B4D'
          }; padding: 2px 15px;`
        : ``}
  }
  @media (max-width: 576px) {
    padding: 5px 10px;
    margin: 5px 10px 0;
    font-size: 13px;
  }
`
export const StyledFaqContainer = styled.div`
  height: 300px;
  overflow-y: scroll;

  @media (max-width: 576px) {
    height: 220px;
  }
`
