import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from 'styled-components/macro'
import { ButtonProps } from 'reactstrap'

interface IButtonProps {
  children: string | HTMLElement
}

export default function Button({
  children,
  disabled,
  ...restProps
}: IButtonProps & ButtonProps) {
  return (
    <button
      css={`
        background: ${disabled ? 'grey' : '#c00a2e'};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        color: white;
        height: 40px;
        font-size: 14px;
        min-width: 90px;
        width: 100%;
        border: none;
        margin: 10px 0;
      `}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </button>
  )
}
