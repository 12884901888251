export const message = {
  USER_CREATE_MSG: 'User created successfully',
  USER_UPDATE_MSG: 'User updated successfully',
  USER_DELETE_MSG: 'User deleted successfully',
  USER_REGISTER_MSG: 'You have successfully registered',
}

export const PaginationParams = {
  Offset: 0,
  Limit: 10000,
}

export const LoginCredentails = {
  email: 'manikyamallu@gmail.com',
  password: 'Pass12!@',
}

export const LoginResponse = {
  email: 'manikyamallu@gmail.com',
  name: 'Manikyam',
  mobileNumber: 9441136987,
  appId: '632089028fa3612011b811bc',
  role: 'User',
}

export enum CardTypes {
  MOVIE, // Vertical cards used in most places
  EPISODE, // Detail Page episode cards
  MOVIE_RECOMMENDATIONS, // Detail page recommendations at the bottom
  LATEST_NEWS, // latest news in magazines
  RELATED_NEWS, // news detail view related news section
  PODCASTS, // Podacsts page card
  MUSIC, // Music cards used in few places
  MUSIC_TOP_PLAYLIST, // Music top playlist section
  MUSIC_PLAYLIST_ICON, // Music icon in songs vertical list at bottom
  ALBUM, // Album view
  SIMILAR_SONG, // Album view
  CONTINUE_CARD,
  REGISTER_CARD,
}

export const headerRoutes: { [key: string]: string } = {
  All: '',
  'Web TV': 'tv',
  Magazines: 'magazines',
  Podcast: 'podcasts',
  Music: 'music',
  Radio: 'radio',
}

export const componentNamesForSections: { [key: string]: string } = {
  // talk_shows: 'ContinueWatching',
  // latest_web_series: 'Movies',
}

export enum VideoTypes {
  MOVIES = 'movie',
  WEB_SERIES = 'web_series',
}

export enum VideoPlayerType {
  YOUTUBE = 'youtube',
  SWARA = 'swara',
}

export const PlayerInfo = {
  library: 'https://cdn.jwplayer.com/libraries/WU0eI938.js',
}

export const PdfJsWorkerURL =
  'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'

export enum ImageOrginSource {
  SWARA = 'swara',
  ImageToolKit = 'image_tool_kit',
}

export enum watchLaterContentType {
  Movie = 'movie',
  WebSeries = 'web_series',
  TalkShows = 'talk_shows',
  Interviews = 'interviews',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Others = 'Others',
}

export enum PlatformType {
  Web = 'web',
  Mobile = 'mobile',
  TV = 'tv',
}

export enum Environment {
  Development = 'DEV',
  Staging = 'STAGING',
  Qa = 'QA',
  Production = 'PROD',
}

export enum bannerTypes {
  Movie = 'movie',
  WebSeries = 'web_series',
  TalkShows = 'talk_shows',
  Interviews = 'interviews',
  external = 'external',
  Music = 'music',
  Podcast = 'podcast',
  Magazine = 'magazine',
}

export enum AlbumType {
  Music = 'music',
  Podcast = 'podcast',
}

export enum MediaContentType {
  Movie = 'movie',
  Concert = 'concert',
  Vlog = 'vlog',
  Documentary = 'documentary',
  MovieVideoSong = 'movie_video_song',
  Trailer = 'trailer',
  PrivateVideoSong = 'private_video_song',
  ShortFilm = 'short_film',
  WebSeries = 'web_series',
  TalkShow = 'talk_shows',
  Interview = 'interview',
  Music = 'music',
  Podcast = 'podcast',
  Magazine = 'magazine',
}

export const sectionTypes: { [key: string]: string } = {
  [`${MediaContentType.Movie}`]: 'Movies',
  [`${MediaContentType.Concert}`]: 'Concert',
  [`${MediaContentType.Vlog}`]: 'Vlog',
  [`${MediaContentType.Documentary}`]: 'Documentaries',
  [`${MediaContentType.MovieVideoSong}`]: 'Movie Video Songs',
  [`${MediaContentType.Trailer}`]: 'Trailers',
  [`${MediaContentType.PrivateVideoSong}`]: 'Private Video Songs',
  [`${MediaContentType.ShortFilm}`]: 'Short Films',
  [`${MediaContentType.TalkShow}`]: 'Talk Shows',
  [`${MediaContentType.WebSeries}`]: 'Web Series',
  [`${MediaContentType.Interview}`]: 'Interviews',
  [`${MediaContentType.Music}`]: 'Music',
  [`${MediaContentType.Podcast}`]: 'Podcasts',
  [`${MediaContentType.Magazine}`]: 'Magazines',
}
