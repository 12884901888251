import styled from 'styled-components'

export const StyledTabs = styled.div`
  display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
`

export const StyledTab = styled.div<{ isTabSelected: boolean }>`
  color: ${props => (props.isTabSelected ? '#c00a2e' : '#616161')};
  border-bottom: ${props =>
    `${props.isTabSelected ? '3px solid #c00a2e' : ' 2px solid#616161'}`};
  font-weight: ${props => (props.isTabSelected ? 600 : 400)};
  width: 170px;
  text-align: center;
  cursor: pointer;
`

export const StyledTabContent = styled.div`
  padding: 20px 0;
`
