import styled from 'styled-components'
import { Progress, ProgressProps } from 'reactstrap'

export const StyledContinueCard = styled.div`
  /* @media (min-width: 1296px) {
    width: calc(calc(100vw - 105px) / 6.3);
  }
  width: 220px;
  height: 220px; */
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.5s ease;
  &:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`

export const StyledContinueCardLink = styled.div`
  position: absolute;
  color: white;
  text-decoration: none;
  width: inherit;
  &:hover {
    color: white;
  }
`

export const StyledThumbnail = styled.div`
  width: inherit;
  height: 210px;
  border-radius: 2px;
  img {
    border-radius: 2px;
    width: inherit;
    height: inherit;
  }
`

export const StyledGradientDiv = styled.div`
  position: sticky;
  bottom: 0;
  background: linear-gradient(
    353.57deg,
    #020a27 35.06%,
    rgba(2, 10, 39, 0) 97.7%
  );
  width: 100%;
  opacity: 0.6;
  height: 100%;
`

export const StyledProgress = styled<ProgressProps | any>(Progress)`
  border-radius: 8px;
  bottom: 0.2px;
  position: sticky;
  height: 7px;
  .progress-bar {
    background: ${props => `${props.theme.palette.secondary.btn}`};
  }
`
