import styled from 'styled-components'

export const StyledRadioBtn = styled.div<{
  isRadioOn: boolean
  positionValue?: string
}>`
  position: fixed;
  bottom: ${props => props.positionValue || '20px'};
  right: 20px;
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  div {
    ${props =>
      props.isRadioOn
        ? `animation: circle 1s ease-in-out infinite;`
        : `display: none`}
  }

  @keyframes circle {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`
export const StyledRadioImg = styled.img`
  width: 40px;
  height: 35px;
`

export const StyledCircelWave = styled.div`
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  &:nth-of-type(1) {
    width: 100px;
    height: 100px;
    border: 1px solid #ffc8070d;
    animation-delay: -0.4s;
  }
  &:nth-of-type(2) {
    width: 90px;
    height: 90px;
    border: 1.5px solid #ffc80733;
    animation-delay: -0.3s;
  }

  &:nth-of-type(3) {
    width: 80px;
    height: 80px;
    border: 2px solid #ffc80766;
    animation-delay: -0.2s;
  }

  &:nth-of-type(4) {
    width: 70px;
    height: 70px;
    border: 2.5px solid #ffc80799;
    animation-delay: -0.1s;
  }
`
