import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SeriesState } from './types'

const getLatestWebSeriesOrTalkShowsInfoRequest = createAction<{
  webSeriesType: string
}>('series/getLatestWebSeriesOrTalkShowsInfoRequest')
const getSimilarWebSeriesOrTalkShowsRequest = createAction<{
  webSeriesType: string
  webSeriesId?: string
  genre: string
}>('series/getSimilarWebSeriesOrTalkShowsRequest')
const getWebSeriesSeasonsAndEpisodesRequest = createAction<{
  webSeriesId: string
}>('series/getWebSeriesSeasonsAndEpisodes')
const getEpisodesFromWebSeriesSeasonRequest = createAction<{
  webSeriesId: string
  seasonId: string
}>('series/getEpisodesFromWebSeriesSeasonRequest')
const getWebSeriesInfoRequest = createAction<{ webSeriesId: string }>(
  'series/getWebSeriesInfoRequest',
)
const getEpisodeInfoRequest = createAction<{
  webSeriesId: string
  seasonId: string
  episodeId: string
}>('series/getEpisodeInfoRequest')

export const initialState: SeriesState = {
  latestWebSeriesorTalkshowList: [],
  similarWebSeriesorTalkshowList: [],
  webSeriesSeasonsAndEpisodesInfo: {},
  webSeriesSeasonEpisodes: [],
  episodeInfo: {},
  webseriesInfo: {},
}

const seriesSlice = createSlice({
  name: 'series',
  initialState,
  reducers: {
    setLatestWebSeriesorTalkshowList(state, action: PayloadAction<any>) {
      state.latestWebSeriesorTalkshowList = action.payload
    },
    setSimilarWebSeriesorTalkshowList(state, action: PayloadAction<any>) {
      state.similarWebSeriesorTalkshowList = action.payload
    },
    setWebSeriesSeasonsAndEpisodes(state, action: PayloadAction<any>) {
      state.webSeriesSeasonsAndEpisodesInfo = action.payload
    },
    setWebSeriesSeasonEpisodes(state, action: PayloadAction<any>) {
      state.webSeriesSeasonEpisodes = action.payload
    },
    setWebSeriesInfo(state, action: PayloadAction<any>) {
      state.webseriesInfo = action.payload
    },
    setEpisodeInfo(state, action: PayloadAction<any>) {
      state.episodeInfo = action.payload
    },
    watchWebSeriesLaterSuccess(state) {
      state.webseriesInfo = { ...state.webseriesInfo, watchLaterInfo: true }
    },
    removeWebSeriesFromWatchLaterSuccess(state) {
      state.webseriesInfo = { ...state.webseriesInfo, watchLaterInfo: false }
    },
  },
})

export {
  getLatestWebSeriesOrTalkShowsInfoRequest,
  getSimilarWebSeriesOrTalkShowsRequest,
  getWebSeriesSeasonsAndEpisodesRequest,
  getEpisodesFromWebSeriesSeasonRequest,
  getWebSeriesInfoRequest,
  getEpisodeInfoRequest,
}
export const { actions, reducer, name: sliceKey } = seriesSlice
