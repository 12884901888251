import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from 'styled-components/macro'
import { Label, Input } from 'reactstrap'
import { ErrorMessage } from 'formik'

export default function index({
  id,
  label,
  name,
  className,
  ...restProps
}: any) {
  return (
    <div
      css={`
        label {
          color: ${restProps?.type === 'feedback' ? 'black' : 'white'};
          font-size: 12px;
          margin-bottom: 2px;
          padding: 0 3px;
        }
        input {
          background: ${restProps?.type === 'feedback' ? 'white' : '#393b4d'};
          height: 35px;
          border-color: ${restProps?.type === 'feedback' ? 'white' : '#393b4d'}
          color: ${restProps?.type === 'feedback' ? 'black' : 'white'};
          &:focus,
          &:active {
            background: ${restProps?.type === 'feedback' ? 'white' : '#393b4d'};
            outline: 0;
            box-shadow: 0 0 0 0.15rem rgb(67 71 76 / 73%);
            border-color:${
              restProps?.type === 'feedback' ? 'black' : '#393b4d'
            };
          color: ${restProps?.type === 'feedback' ? 'black' : 'white'};
          }
        }
        padding-bottom: 10px;
      `}
    >
      {label && <Label htmlFor={name}>{label}</Label>}
      <Input id={id} name={name} className={className} {...restProps} />
      {className && (
        <ErrorMessage name={name} component="span" className="error" />
      )}
    </div>
  )
}
