import React from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import CloseIcon from '@material-ui/icons/Close'

type Props = {
  playerRef?: any
  width?: number
  height?: number
  file: string
  imageUrl?: string
  name?: string
  playing?: boolean
  description?: string
  onPlayNextSong?: any
  onPlayPreviousSong?: any
  onPlaySong?: (event: any) => void
  onPauseSong?: any
  onListen?: (event: any) => void
  onClose?: any
}

const AudioPlayerContainer: React.FC<Props> = ({
  file,
  imageUrl,
  name,
  description,
  playing,
  playerRef,
  onPlaySong,
  onPauseSong,
  onPlayNextSong,
  onListen,
  onPlayPreviousSong,
  onClose,
}) => {
  return (
    <div className="audio-player-container" id="audio-player">
      <div
        className="d-flex"
        style={{ position: 'absolute', top: 32, left: 36 }}
      >
        <img
          height={45}
          width={45}
          style={{ borderRadius: 45 }}
          src={imageUrl}
          alt={name}
        />
        <div className="dm-none audio-info text-white mx-3">
          <div className="audio-title" title={name}>
            {name}
          </div>
          <div className="audio-description" title={description}>
            {description}
          </div>
        </div>
      </div>

      <span style={{ position: 'absolute', right: 0, top: 0 }}>
        <CloseIcon
          className="cursor-pointer"
          fontSize="medium"
          color="error"
          onClick={() => onClose()}
        ></CloseIcon>
      </span>
      <AudioPlayer
        autoPlay={playing}
        ref={playerRef}
        src={file}
        customProgressBarSection={[
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.CURRENT_TIME,
          <div>/</div>,
          RHAP_UI.DURATION,
        ]}
        showSkipControls
        showJumpControls={false}
        onPlay={onPlaySong}
        onPause={() => onPauseSong()}
        onEnded={() => onPlayNextSong()}
        onClickNext={() => onPlayNextSong()}
        onListen={onListen}
        onSeeked={onListen}
        listenInterval={5000}
        onClickPrevious={() => onPlayPreviousSong()}
      />
    </div>
  )
}
export default AudioPlayerContainer
