import React from 'react'
import { Spinner } from 'reactstrap'

const Loader = () => {
  return (
    <div className="preloader">
      <div className="status">
        <Spinner color="danger" height="80" width="80" aria-label="loading" />
      </div>
    </div>
  )
}

export default Loader
