import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
  count: number
  width?: number | string
  height?: number | string
  className?: string
}

const SkeltonLoader: React.FC<Props> = ({
  count,
  width,
  height,
  className,
}): any => {
  return (
    <SkeletonTheme
      width={width}
      height={height}
      duration={1}
      inline={true}
      baseColor="#DDDBDD"
      highlightColor="#fff"
    >
      <Skeleton
        width={width}
        height={height}
        count={count}
        className={className}
      />
    </SkeletonTheme>
  )
}

export default SkeltonLoader
