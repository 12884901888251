import React, { useState } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from 'styled-components/macro'
import {
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

interface CustomDropdownProps {
  label?: string
  items: any
  selectItem: any
  className?: string | undefined
  onMenuItemChange: (item: any) => void
}

const CustomDropdownMenu = ({
  label,
  items,
  selectItem,
  className,
  onMenuItemChange,
}: CustomDropdownProps) => {
  const [dropdownOpen, setDropdownMenu] = useState<boolean>(false)

  const handleToggle = () => {
    setDropdownMenu(!dropdownOpen)
  }

  return (
    <div
      css={`
        width: fit-content;
        label {
          color: white;
          font-size: 12px;
          margin-bottom: 2px;
          padding: 0 3px;
        }
        .dropdown,
        .dropdown-toggle {
          width: 100%;
          background: #393b4d;
          height: 35px;
          border-color: #393b4d;
          color: white;
          border-radius: 5px;
          &:focus,
          &:active {
            background: #393b4d;
            outline: 0;
            box-shadow: 0 0 0 0.15rem rgb(67 71 76 / 73%);
            border-color: #393b4d;
            color: white;
          }
        }
        .dropdown-menu {
          width: inherit;
        }
      `}
    >
      {label && <Label>{label}</Label>}
      <Dropdown isOpen={dropdownOpen} toggle={handleToggle}>
        <DropdownToggle
          css={`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
          className={className}
          caret
        >
          <div>{selectItem?.label}</div>
        </DropdownToggle>
        <DropdownMenu>
          {items &&
            items.map((item: { label: any; value: any }, i: number) => {
              return (
                <DropdownItem key={i} onClick={() => onMenuItemChange(item)}>
                  {item.label}
                </DropdownItem>
              )
            })}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default CustomDropdownMenu
