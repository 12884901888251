import styled from 'styled-components'

export const StyledDetailsBanner = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  img {
    width: inherit;
    height: 100%;
  }
`

export const StyledBannerlDiv = styled.div<{
  fontSize: string
  fontWeight: string
}>`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  height: 75px;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const StyledBannerWatchLaterDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #292626;
  justify-content: center;
  font-size: 13px;
  padding: 4px;
  border-radius: 14px img {
    width: 100;
    height: 100;
  }
`

export const StyledBannerWatchLaterButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledBannerButton = styled.button<{
  width: string
  bgColor: string
  color: string
  borderColor: string
}>`
  width: ${props => props.width};
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  border-radius: 5px;
  border-color: ${props => props.borderColor};
  &:active {
    border-color: ${props => props.borderColor};
    outline: none;
  }
`
export const StyledBannerSaveButton = styled.div<{
  width: string
  bgColor: string
  color: string
  borderColor: string
}>`
  width: ${props => props.width};
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  border-radius: 5px;
  border-color: ${props => props.borderColor};
  align-items: center;
  cursor: pointer;
  &:active {
    border-color: ${props => props.borderColor};
    outline: none;
  }
`
