import React from 'react'
import { StyledTab, StyledTabContent, StyledTabs } from './StyledComponents'

interface TabsProps {
  tabs: any
  children: any
  selectedTab: number
  onTabClick(index: number): void
}

const Index = ({ tabs, children, selectedTab, onTabClick }: TabsProps) => {
  return (
    <>
      <StyledTabs>
        {tabs.map((tab: any, index: number) => {
          return (
            <StyledTab
              key={index}
              onClick={() => onTabClick(index)}
              isTabSelected={selectedTab === index}
            >
              {tab.name}
            </StyledTab>
          )
        })}
      </StyledTabs>
      <StyledTabContent>
        {children.length ? children[selectedTab] : children}
      </StyledTabContent>
    </>
  )
}

export default Index
