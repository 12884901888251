import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import NotFound from 'components/NotFound'
import { PublicRoute } from './PublicRoute'
import Loader from 'components/Common/Loader'
import RouteWithLayout from './RouteWithLayout'
import { PrivateRoute } from './PrivateRoute'

const Login = React.lazy(() => import('containers/Auth/Login'))
const Register = React.lazy(() => import('containers/Auth/Register'))
const ResetPassword = React.lazy(() => import('containers/Auth/ResetPassword'))
const VerifyRegisterEmail = React.lazy(
  () => import('containers/Auth/VerifyRegisterEmail'),
)
const ForgotPassword = React.lazy(
  () => import('containers/Auth/ForgotPassword'),
)
const ChangePassword = React.lazy(
  () => import('containers/Auth/ChangePassword'),
)
const Profile = React.lazy(() => import('containers/Profile'))
const Home = React.lazy(() => import('containers/Home'))
const WebSeriesDetails = React.lazy(() => import('containers/SeriesDetails'))
const MovieDetails = React.lazy(() => import('containers/MovieDetails'))
const WatchLater = React.lazy(() => import('containers/Home/WatchLater'))
const AlbumDetails = React.lazy(() => import('containers/AlbumDetails'))
const MusicDetails = React.lazy(() => import('containers/MusicDetails'))
const PodCastDetails = React.lazy(() => import('containers/PodCastDetails'))
const PDFDetails = React.lazy(() => import('containers/Home/MagazineDetails'))
const PrivacyPolicy = React.lazy(
  () => import('containers/Profile/Content/PrivacyPolicy'),
)
const AboutUs = React.lazy(() => import('containers/Profile/Content/AboutUs'))
const PDFDetails2 = React.lazy(
  () => import('containers/Home/MagazineDetails/react-pdf-viewer'),
)
const SocialLoginSuccess = React.lazy(
  () => import('containers/Auth/SocialLoginSuccess'),
)
const Feedback = React.lazy(() => import('containers/Feedback'))

const AppRoutes = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/login" element={<PublicRoute component={Login} />} />
        <Route
          path="/register"
          element={<PublicRoute component={Register} />}
        />
        <Route
          path="/verify-email"
          element={<PublicRoute component={VerifyRegisterEmail} />}
        />
        <Route
          path="/forgot-password"
          element={<PublicRoute component={ForgotPassword} />}
        />
        <Route
          path="/reset-password"
          element={<PublicRoute component={ResetPassword} />}
        />
        <Route
          path="/change-password"
          element={<PublicRoute component={ChangePassword} />}
        />
        {/* Below Routes are with Layout  header and footer */}
        <Route path="/" element={<RouteWithLayout component={Home} />} />
        <Route
          path="/services/:categoryType"
          element={<RouteWithLayout component={Home} />}
        />
        <Route
          path="/series/:seriesType/:seriesId"
          element={<RouteWithLayout component={WebSeriesDetails} />}
        />
        <Route
          path="/series/:seriesType/:seriesId/watch"
          element={<RouteWithLayout component={WebSeriesDetails} />}
        />
        <Route
          path="/movies/:movieId"
          element={<RouteWithLayout component={MovieDetails} />}
        />
        <Route
          path="/albums/:albumId"
          element={<RouteWithLayout component={AlbumDetails} />}
        />
        <Route
          path="/music/:trackId"
          element={<RouteWithLayout component={MusicDetails} />}
        />
        <Route
          path="/podcasts/:podcastId"
          element={<RouteWithLayout component={PodCastDetails} />}
        />
        <Route path="/magazine/:magazineId" element={<PDFDetails />} />
        <Route path="/magazines/:magazineId" element={<PDFDetails2 />} />
        <Route path="/success" element={<SocialLoginSuccess />} />
        <Route
          path="/movies/:movieId/watch"
          element={<RouteWithLayout component={MovieDetails} />}
        />
        <Route
          path="/watch-later"
          element={<RouteWithLayout component={WatchLater} />}
        />
        <Route path="/profile" element={<PrivateRoute component={Profile} />} />
        <Route
          path="/privacy-policy"
          element={<RouteWithLayout component={PrivacyPolicy} />}
        />
        <Route
          path="/about-us"
          element={<RouteWithLayout component={AboutUs} />}
        />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Suspense>
  )
}
export default AppRoutes
