import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthState } from './types'

const loginRequest = createAction<{ data: any; navigate: any; from: string }>(
  'auth/loginRequest',
)
const registerRequest = createAction<{ data: any; navigate: any }>(
  'auth/registerRequest',
)
const forgotPaswordRequest = createAction<{ data: any; navigate: any }>(
  'auth/forgotPaswordRequest',
)
const verifyForgotPasswordRequest = createAction<{ data: any; navigate: any }>(
  'auth/verifyForgotPasswordRequest',
)
const verifyRegistrationRequest = createAction<{ data: any; navigate: any }>(
  'auth/verifyRegistrationRequest',
)
const verifyFederatedLoginRequest = createAction<{ data: any; navigate: any }>(
  'auth/verifyFederatedLoginRequest',
)

const resendVerificationCodeRequest = createAction<{
  data: any
  navigate: any
}>('auth/resendVerificationCodeRequest')
const logoutRequest = createAction<{
  navigate: any
}>('auth/logoutRequest')

export const initialState: AuthState = {
  isAuthenticated: false,
  userData: null,
}

const loginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedIn(state, action: PayloadAction<any>) {
      state.isAuthenticated = true
      state.userData = action.payload
    },
    loggedOut(state) {
      state.isAuthenticated = false
      state.userData = null
    },
  },
})
export {
  loginRequest,
  registerRequest,
  forgotPaswordRequest,
  verifyForgotPasswordRequest,
  verifyRegistrationRequest,
  resendVerificationCodeRequest,
  verifyFederatedLoginRequest,
  logoutRequest,
}
export const { actions, reducer, name: sliceKey } = loginSlice
