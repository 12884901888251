import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  ChangePasswordPayload,
  EditUserPreferencesPayload,
  UserInfoPayload,
} from './types'
import {
  getUserInfoRequest,
  updateUserInfoRequest,
  changePasswordRequest,
  listPreferencesRequest,
  getUserPreferencesRequest,
  createUserPreferencesRequest,
  editUserPreferencesRequest,
  actions,
} from './slice'

import { actions as categoryActions } from '../category/slice'

function* getUserInfo(): any {
  try {
    const response = yield call(baseAxios.get, apiEndPoints.user.getUserInfo)
    if (response.data) yield put(actions.setUserInfo(response.data))
  } catch (err) {
    console.log(err)
  }
}
function* updateUserInfo({ payload }: { payload: UserInfoPayload }): any {
  try {
    const response = yield call(
      baseAxios.put,
      apiEndPoints.user.updateUserInfo,
      payload,
    )
    if (response.data) yield put(actions.setUserInfo(response.data))
  } catch (err) {
    console.log(err)
  }
}
function* changePassword({ payload }: { payload: ChangePasswordPayload }): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.user.changePassword,
      payload,
    )
    if (response.data) yield put(actions.setUserInfo(response.data))
  } catch (err) {
    console.log(err)
  }
}
function* listPreferences(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.user.listPreferences,
    )
    if (response.data) yield put(actions.setPreferences(response.data))
  } catch (err) {
    console.log(err)
  }
}
export function* getUserPreferences(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.user.userPreferences,
    )
    if (response.data) yield put(actions.setUserPreferences(response.data))
  } catch (err) {
    console.log(err)
  }
}
function* createUserPreferences({ payload }: { payload: string[] }): any {
  try {
    const response = yield call(
      baseAxios.post,
      apiEndPoints.user.userPreferences,
      { preferences: payload },
    )
    if (response.data) yield put(actions.setUserPreferences(response.data))
  } catch (err) {
    console.log(err)
  }
}
function* editUserPreferences({
  payload: { preferences, preferenceId },
}: {
  payload: EditUserPreferencesPayload
}): any {
  try {
    const response = yield call(
      baseAxios.put,
      apiEndPoints.user.editUserPreferences(preferenceId),
      { preferences },
    )
    if (response.data) {
      yield put(actions.setUserPreferences(response.data))
      yield put(categoryActions.resetAllSectionData())
    }
  } catch (err) {
    console.log(err)
  }
}

export function* userSaga(): any {
  yield all([
    yield takeLatest(getUserInfoRequest, getUserInfo),
    yield takeLatest(updateUserInfoRequest, updateUserInfo),
    yield takeLatest(changePasswordRequest, changePassword),
    yield takeLatest(listPreferencesRequest, listPreferences),
    yield takeLatest(getUserPreferencesRequest, getUserPreferences),
    yield takeLatest(createUserPreferencesRequest, createUserPreferences),
    yield takeLatest(editUserPreferencesRequest, editUserPreferences),
  ])
}
