import React, { useMemo } from 'react'
import Footer from 'components/Layouts/Footer'
import useWindowSize from 'hooks/useWindowSize'
import MobileHeader from 'components/Layouts/MobileHeader'
import Header from 'components/Layouts/Header'

interface RouteWithLayoutProps {
  component: React.ComponentType
}

const RouteWithLayout: React.FC<RouteWithLayoutProps> = ({
  component: Content,
}) => {
  const [width] = useWindowSize()
  const showMobileHeader = useMemo(() => width <= 1024, [width])
  return (
    <div className="rootDiv">
      {width !== 0 && showMobileHeader ? <MobileHeader /> : <Header />}
      <div
        style={{
          minHeight: width !== 0 && showMobileHeader ? '80vh' : '100vh',
          backgroundColor: '#14182d',
        }}
      >
        <Content />
      </div>
      <Footer />
    </div>
  )
}

export default RouteWithLayout
