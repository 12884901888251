import React, { useRef, useEffect, useState } from 'react'
import { OnProgressProps } from 'react-player/base'
import ReactPlayer from 'react-player/youtube'
import { useDispatch, useSelector } from 'react-redux'
import { selectMusic } from 'store/music/selector'
import { actions } from 'store/music/slice'

type Props = {
  width?: number
  height?: number
  file: string
  lastWacthedTime: number
  onPlay?: () => void
  onEnded?: () => void
  onProgress?: (seconds: number, timeSpent: number) => void
}

const YouTubePlayerContainer: React.FC<Props> = ({
  height,
  width,
  file,
  onEnded,
  onPlay,
  onProgress,
  lastWacthedTime,
}) => {
  const playerRef: any = useRef<ReactPlayer>(null)
  const [startTime, setStartTime] = useState(0)
  const [pausedTime, setPausedTime] = useState(0)
  const [pausedStartTime, setPausedStartTime] = useState<any>(null)
  const { videoPlaying } = useSelector(selectMusic)
  const dispatch = useDispatch()

  useEffect(() => {
    setStartTime(Date.now())
    if (lastWacthedTime && playerRef) {
      playerRef.current?.seekTo(lastWacthedTime, 'seconds')
      dispatch(actions.setVideoPlaying(true))
    }
  }, [dispatch, lastWacthedTime])

  const handleOnVideoEnd = () => {
    onEnded && onEnded()
  }

  const handleOnPlay = () => {
    if (playerRef?.current?.getCurrentTime() >= 1) {
      const pauseTime = (Date.now() - pausedStartTime) / 1000
      setPausedTime((prevPausedTime: number) => prevPausedTime + pauseTime)
      setPausedStartTime(null)
    }
    dispatch(actions.setVideoPlaying(true))
    dispatch(actions.setRadioPlaying(false))
  }

  const handleOnProgress = (changeState: OnProgressProps) => {
    if (Math.round(changeState.playedSeconds) % 5 === 0) {
      const currentTime = Date.now()
      const elapsedTime = (currentTime - startTime) / 1000 - pausedTime
      onProgress && onProgress(changeState.playedSeconds, elapsedTime)
    }
    onPlay && onPlay()
  }

  const handleOnPause = () => {
    setPausedStartTime(Date.now())
    dispatch(actions.setVideoPlaying(false))
  }

  return (
    <ReactPlayer
      ref={playerRef}
      url={file}
      playing={videoPlaying}
      height={height || '100%'}
      width={width || '100%'}
      controls={true}
      onEnded={handleOnVideoEnd}
      onPlay={handleOnPlay}
      onPause={handleOnPause}
      progressInterval={15000}
      onProgress={handleOnProgress}
      config={{ playerVars: { autoplay: true } }}
    />
  )
}
export default YouTubePlayerContainer
