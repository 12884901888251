import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MoviesState } from './types'

const getLatestMoviesRequest = createAction<{
  skip: number
  limit: number
}>('movies/latestMoviesRequest')
const getMovieInfoRequest = createAction<{ movieId: string }>(
  'movies/getMovieInfoRequest',
)
const getSimilarMoviesRequest = createAction<{
  genre: string
  skip: number
  limit: number
  movieId?: string
}>('movies/getSimilarMoviesRequest')

export const initialState: MoviesState = {
  movieList: [],
  isMoreData: true,
  movieInfo: {},
  similarMovies: [],
}

const moviesSlice = createSlice({
  name: 'movies',
  initialState,
  reducers: {
    setMovies(state: MoviesState, action: PayloadAction<any>) {
      state.movieList = [...state.movieList, ...action.payload]
    },
    setIsMoreData(state: MoviesState, action: PayloadAction<any>) {
      state.isMoreData = action.payload
    },
    setMovieInfo(state: MoviesState, action: PayloadAction<any>) {
      state.movieInfo = action.payload
    },
    setSimilarMovies(state: MoviesState, action: PayloadAction<any>) {
      state.similarMovies = action.payload
    },
    watchMovieLaterSuccess(state) {
      state.movieInfo = { ...state.movieInfo, watchLaterInfo: true }
    },
    removeMovieFromWatchLaterSuccess(state) {
      state.movieInfo = { ...state.movieInfo, watchLaterInfo: false }
    },
  },
})

export { getLatestMoviesRequest, getMovieInfoRequest, getSimilarMoviesRequest }
export const { actions, reducer, name: sliceKey } = moviesSlice
