import styled from 'styled-components'

export const StyledTrayContainer = styled.div` css: any
  width: 100%;
  color: white;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.primary.main};
`

export const StyledTrayHeader = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`

export const StyledTrayList = styled.div`
  width: 100%;
  min-height: 180px;
  white-space: nowrap;
`

export const PrevButton = styled.div`
  left: 0px;
  /* transform: rotate(-45deg); */
  z-index: 2;
  display: inline-block;
  position: absolute;
  cursor: pointer;
  color: white;
  min-height: 270px;
  height: 278px;
  width: 50px;
  opacity: 1;
  background: linear-gradient(
    to left,
    transparent,
    rgba(6, 69, 193, 0.7),
    rgba(12, 17, 27, 0.9)
  );
  /* linear-gradient(
    to left,
    transparent,
    rgba(12, 17, 27, 0.7),
    rgba(12, 17, 27, 0.9)
  ); */

  ::before ::after {
    content: '';
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    width: 15px;
    height: 15px !important;
  }
`

export const NextButton = styled.div`
  right: 0px;
  transform: rotate(180deg);
  z-index: 2;
  display: inline-block;
  position: absolute;
  cursor: pointer;
  color: white;
  min-height: 270px;
  height: 278px;
  width: 50px;
  opacity: 1;
  background: linear-gradient(
    to left,
    transparent,
    rgba(4, 72, 207, 0.7),
    rgba(12, 17, 27, 0.9)
  );
`
