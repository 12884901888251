import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from 'styled-components/macro'
import { Label } from 'reactstrap'
import PhoneInput from 'react-phone-input-2'
import { ErrorMessage } from 'formik'
import 'react-phone-input-2/lib/style.css'

export default function PhoneInputField({
  name,
  label,
  className,
  onPhoneInputChange,
  ...restProps
}: any) {
  return (
    <div
      css={`
        label {
          color: ${restProps?.type === 'feedback' ? 'black' : 'white'};
          font-size: 12px;
          margin-bottom: 2px;
          padding: 0 3px;
        }
        .react-tel-input {
          .selected-flag {
            top: -1px;
          }

          .flag-dropdown,
          .selected-flag,
          input {
            background: ${restProps?.type === 'feedback' ? 'white' : '#393b4d'};
            height: 35px;
            border-color: ${restProps?.type === 'feedback' ? '' : '#393b4d'};
            color: ${restProps?.type === 'feedback' ? 'black' : 'white'};
            &:hover,
            &:focus,
            &:active {
              background: ${restProps?.type === 'feedback'
                ? 'white'
                : '#393b4d'};
              outline: 0;
              box-shadow: 0 0 0 0.15rem rgb(67 71 76 / 73%);
              border-color: ${restProps?.type === 'feedback' ? '' : '#393b4d'};
              color: ${restProps?.type === 'feedback' ? 'black' : 'white'};
            }
          }
        }
        padding-bottom: 10px;
      `}
    >
      {label && <Label htmlFor={name}>{label}</Label>}
      <PhoneInput
        enableTerritories={true}
        disableSearchIcon={true}
        countryCodeEditable={false}
        onChange={onPhoneInputChange}
        {...restProps}
      />
      {className && (
        <ErrorMessage name={name} component="span" className="error" />
      )}
    </div>
  )
}
