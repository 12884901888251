import React, { useEffect } from 'react'
import { unstable_HistoryRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import AppRoutes from 'routes'
import Radio from 'containers/Radio'
import 'styles/globalStyles.css'
import { useDispatch } from 'react-redux'
import { actions } from 'store/auth/slice'
import { actions as musicActions } from 'store/music/slice'
import Loader from 'components/Common/Loader'
import { getApiLoaderState, localStorageGetItem } from 'utils/helpers/functions'
import AudioPlayer from 'containers/AudioPlayer'
import { Environment } from 'utils/helpers/constants'
import history from 'utils/history'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const tokenExists = !!localStorage.getItem('TOKEN')
    const userData = localStorage.getItem('USER_DATA')
    const currentTrackDetails = localStorageGetItem('currentTrackDetails')
    if (tokenExists)
      dispatch(actions.loggedIn({ isAuthenticated: true, userData }))
    if (currentTrackDetails) {
      const lastAudioTrack = localStorageGetItem('lastAudioTrack')
      const trackList = localStorageGetItem('trackList')
      dispatch(
        musicActions.setCurrentTrackDetails({
          ...currentTrackDetails,
          lastWatchedTime: lastAudioTrack?.audioPlayedTime || 0,
        }),
      )
      dispatch(musicActions.setTrackList(trackList))
    }
  }, [dispatch])

  useEffect(() => {
    const env: any = `${process.env.REACT_APP_ENV}`
    if (
      ![
        Environment.Development,
        Environment.Qa,
        Environment.Staging,
        Environment.Production,
      ].includes(env)
    ) {
      const handleContextmenu = (e: any) => {
        e.preventDefault()
      }
      document.addEventListener('contextmenu', handleContextmenu)
      return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
      }
    }
  }, [])

  const logoutLoading = getApiLoaderState('logout')

  return (
    <Router history={history}>
      <AppRoutes />
      <ToastContainer
        position="top-right"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
      />
      <Radio />
      <AudioPlayer />
      {logoutLoading?.loading && <Loader />}
    </Router>
  )
}

export default App
