import styled from 'styled-components'

export const StyledGenericCard = styled.div<{
  widthFactor: number
  heightFactor?: number
  showTitle: boolean
  css: any
}>`
  /* width: ${props => props.css.width || '160px'};
  @media (min-width: 1296px) {
    width: ${props => `calc(calc(100vw - 105px) / ${props.widthFactor})`};
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: ${props => props.css.width || '130px'};
  } */
  /* height: ${props => props.css.height || '240px'}; */
  /* padding-bottom: calc(props.heightFactor + 0.5 if title exists * 100%); */
  cursor: pointer;
  display: inline-block;
  height: auto;
  white-space: normal;
  border-radius: ${props => (props.showTitle ? `7px 7px 0px 0px` : `7px`)};
  /* filter: drop-shadow(6px 6px 6px rgba(0, 0, 0, 0.36)); */
  transition: all 0.5s ease;
  // &:hover {
  //   -webkit-transform: scale(1.1);
  //   -ms-transform: scale(1.1);
  //   transform: scale(1.1);
  // }
  /* box-shadow: 0 -2px 10px rgb(0 0 0 / 0.2); */
`

export const StyledGenericCardLink = styled.div<{
  css?: any
}>`
  // position: absolute;
  color: white;
  text-decoration: none;
  width: 100%;
  &:hover {
    color: white;
  }
`

export const StyledThumbnail = styled.div<{ css?: { height: string } }>`
  width: 100%;
  border-radius: 7px;
  /* TODO remove after image from cloud as set with proper sizes */
  height: ${props => props.css?.height || '240px'};
  position: relative;
  img {
    border-radius: 7px;
    width: inherit;
    height: inherit;
    position: absolute;
    /* background: linear-gradient(
    353.57deg,
    #020a27 35.06%,
    rgba(2, 10, 39, 0) 97.7%
  ); */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow to the bottom */
  }
`

export const StyledThumbnailTitle = styled.div`
  font-size: small;
  padding: 7px 3px;
  text-transform: capitalize;
`

export const StyledGradientDiv = styled.div`
  position: absolute;
  bottom: 0px;
  background: linear-gradient(
    353.57deg,
    #020a27 35.06%,
    rgba(2, 10, 39, 0) 97.7%
  );
  width: 100%;
  height: 100%;
  opacity: 0.6;
  border-radius: 7px;
`

export const StyledRegisterTextDiv = styled.div`
  font-size: 10px;
  padding: 2px;
  text-transform: capitalize;
`
