import styled from 'styled-components'

export const StyledAccordionContainer = styled.div`
  .accordion-item {
    margin-bottom: 10px;
    border-bottom: 0 !important;
    background-color: #393b4d !important;
    border-radius: 15px !important;
    color: #ffffff !important;
    padding: 0 12px;
  }

  .accordion-button {
    background-color: #393b4d !important;
    border-radius: 15px 15px 0 0 !important;
    box-shadow: none !important;
    color: #ffffff !important;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 0.4px;
    line-height: 140%;
  }

  .accordion-button:not(.collapsed) {
    border-bottom: 1px solid #616161 !important;
  }

  .accordion-button:focus {
    outline: none !important;
  }

  .accordion-button.collapsed {
    border-radius: 15px !important;
  }

  .accordion-collapse {
    background-color: #393b4d !important;
    border-radius: 0 0 15px 15px !important;
    font-weight: 400;
  }
`
