import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  postContentToWatchLater,
  removeContentFromWatchLater,
  WatchLaterState,
} from './types'

const addContentToWatchLaterRequest = createAction<postContentToWatchLater>(
  'watchLater/addContentToWatchLater',
)

const removeContentFromWatchLaterRequest =
  createAction<removeContentFromWatchLater>(
    'watchLater/removeContentFromWatchLater',
  )

const getWatchLaterInfoRequest = createAction<{
  type: string
}>('watchLater/getWatchLaterInfo')

const getWatchLaterContentTypesInfoRequest = createAction(
  'watchLater/getWatchLaterContentTypes',
)

export const initialState: WatchLaterState = {
  watchLaterData: {},
  watchLaterContentTypes: [],
}

const seriesSlice = createSlice({
  name: 'watchlater',
  initialState,
  reducers: {
    setWatchLaterData(state, action: PayloadAction<any>) {
      state.watchLaterData = action.payload
    },
    setWatchLaterContentTypes(state, action: PayloadAction<any>) {
      state.watchLaterContentTypes = action.payload
    },
  },
})

export {
  addContentToWatchLaterRequest,
  removeContentFromWatchLaterRequest,
  getWatchLaterInfoRequest,
  getWatchLaterContentTypesInfoRequest,
}
export const { actions, reducer, name: sliceKey } = seriesSlice
