import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MagazinesState } from './types'

const getMagazineInfoRequest = createAction<{ magazineId: string }>(
  'magazines/getMagazineInfoRequest',
)

export const initialState: MagazinesState = {
  magazineInfo: {},
}

const magazinesSlice = createSlice({
  name: 'magazines',
  initialState,
  reducers: {
    setMagazineInfo(state, action: PayloadAction<any>) {
      state.magazineInfo = action.payload
    },
  },
})

export { getMagazineInfoRequest }
export const { actions, reducer, name: sliceKey } = magazinesSlice
