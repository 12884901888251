import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  actions,
  getLatestMoviesRequest,
  getMovieInfoRequest,
  getSimilarMoviesRequest,
} from './slice'

function* getLatestMovies({ payload }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.movies.getLatestMovies,
      { params: { ...payload } },
    )

    if (response.data.length) yield put(actions.setMovies(response.data))

    if (response.data.length === 0 || response.data.length < payload.limit)
      yield put(actions.setIsMoreData(false))
  } catch (err) {
    console.log(err)
  }
}

function* getMovieInfo({ payload: { movieId } }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.movies.getMovieInfo(movieId),
    )
    if (response.data) {
      yield put(actions.setMovieInfo(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* getSimilarMovies({
  payload: { genre, skip, limit, movieId },
}: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.movies.getSimilarMovies(genre, skip, limit, movieId),
    )
    if (response.data) {
      yield put(actions.setSimilarMovies(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* moviesSaga(): any {
  yield all([
    yield takeLatest(getLatestMoviesRequest, getLatestMovies),
    yield takeLatest(getMovieInfoRequest, getMovieInfo),
    yield takeLatest(getSimilarMoviesRequest, getSimilarMovies),
  ])
}
