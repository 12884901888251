import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { actions, getMagazineInfoRequest } from './slice'

function* getMagazineInfo({ payload: { magazineId } }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.magazines.getMagazineInfo(magazineId),
    )
    if (response.data) {
      yield put(actions.setMagazineInfo(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* magazinesSaga(): any {
  yield all([yield takeLatest(getMagazineInfoRequest, getMagazineInfo)])
}
