import { baseAxios } from 'api/axios'
import { baseAxios as CmsAxios } from 'api/CmsAxios'
import { apiEndPoints } from 'api/variables'
import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  actions,
  getGlobalSearchRequest,
  captureVideoActivityRequest,
  captureAudioActivityRequest,
  getContinueWatchingRequest,
  getSectionWiseDataRequest,
  getVideoFileInfoRequest,
  resetSearchInfoRequest,
  getRadioEventsRequest,
  getRadioHostsRequest,
  getRadioSponsorsRequest,
  registerForRadioShowEventRequest,
  getWeeklyRadioEventsRequest,
  sendFeedback,
} from './slice'
import { toast } from 'react-toastify'

function* getSectionWiseData({ payload }: any): any {
  try {
    yield put(
      actions.setSectionsLoading({
        loading: true,
        sectionDetails: payload.section,
      }),
    )
    const response = yield call(baseAxios.get, payload.section.URL, {
      params: { limit: payload.limit, skip: payload.skip },
    })

    if (response.data.length) {
      yield put(
        actions.setSectionsData({
          payload: {
            data: [...response.data],
            isMoreData:
              response.data.length === 0 || response.data.length < payload.limit
                ? false
                : true,
            loading: false,
          },
          sectionDetails: payload.section,
        }),
      )
    } else {
      yield put(
        actions.setSectionsData({
          payload: {
            data: [],
            isMoreData: false,
            loading: false,
          },
          sectionDetails: payload.section,
        }),
      )
    }
  } catch (err) {
    console.log(err)
  }
}

function* getvideoFileInfo({ payload: { videoId, type } }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.videoInfo(videoId),
      {
        params: { type },
      },
    )
    if (response?.data) yield put(actions.setVideoInfo(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* captureVideoActivity({ payload: data }: any): any {
  try {
    yield call(
      baseAxios.post,
      apiEndPoints.userAnalytics.captureVideoActivity,
      data,
    )
  } catch (err) {
    console.log(err)
  }
}

function* captureAudioActivity({ payload: data }: any): any {
  try {
    yield call(
      baseAxios.post,
      apiEndPoints.userAnalytics.captureAudioActivity,
      data,
    )
  } catch (err) {
    console.log(err)
  }
}

function* getContinueWatching(): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.userAnalytics.continueWatching,
    )
    if (response?.data) {
      response.data = response?.data.map((res: any) => {
        res.percentage =
          !res.percentage || res.percentage < 0
            ? 0
            : res.percentage >= 100
            ? 100
            : res.percentage
        return res
      })
      yield put(actions.setContinueWatching(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* getGlobalSearchInfo({ payload: { searchValue } }: any): any {
  try {
    yield put(actions.setSearchData({}))
    const response = yield call(
      baseAxios.post,
      apiEndPoints.search.getSearchContent,
      { searchValue },
    )
    yield put(
      actions.setSearchObject({
        searchValue,
        searchResultCount: response?.data?.length,
      }),
    )
    if (response?.data?.length) {
      response.data = response.data.map((res: any) => {
        res._id = res?.metaInfo?._id || res._id
        return res
      })
      const searchData = _.groupBy(response.data, dt => dt.type)

      yield put(actions.setSearchData(searchData))
    }
  } catch (err) {
    console.log(err)
  }
}

function* resetSearchInfo(): any {
  yield put(actions.setSearchData({}))
  yield put(actions.setSearchObject({}))
}

function* getRadioEventsData(): any {
  try {
    const response = yield call(
      CmsAxios.get,
      apiEndPoints.radio.getWeeklyRegisterableRadioEvents,
      {
        params: { limit: 100, skip: 0 },
      },
    )
    if (response?.data) {
      response.data = response?.data.map((res: any) => {
        return res
      })
      yield put(actions.setRadioEventsData(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* getWeeklyRadioEventsData(): any {
  try {
    const response = yield call(
      CmsAxios.get,
      apiEndPoints.radio.getWeeklyRadioEvents,
    )
    yield put(actions.setWeeklyRadioEventsData(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* getRadioSponsorsData(): any {
  try {
    const response = yield call(
      CmsAxios.get,
      apiEndPoints.radio.getRadioSponsors,
    )
    if (response?.data) {
      response.data = response?.data.map((res: any) => {
        return res
      })
      yield put(actions.setRadioSponsorsData(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* getRadioHostsData(): any {
  try {
    const response = yield call(CmsAxios.get, apiEndPoints.radio.getRadioHosts)
    if (response?.data) {
      response.data = response?.data.map((res: any) => {
        return res
      })
      yield put(actions.setRadioHostsData(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* registerForRadioShowEvent({ payload }: any): any {
  try {
    yield call(
      CmsAxios.post,
      apiEndPoints.radio.registerForRadioShowEvents(
        payload.radioShowId,
        payload.radioShowEventId,
      ),
      { email: payload.email, userId: payload.userId },
    )

    toast.success('Registered to the radio event Successfully!')
  } catch (err) {
    console.log(err)
  }
}

function* sendFeedbacks({ payload: { data, navigate } }: any): any {
  try {
    const response = yield call(
      CmsAxios.post,
      apiEndPoints.feedback.feedback,
      data,
    )
    if (response?.data) {
      navigate('/')
    }
  } catch (err) {
    console.log(err)
  }
}

export function* commonSaga(): any {
  yield all([
    yield takeEvery(getSectionWiseDataRequest, getSectionWiseData),
    yield takeLatest(getVideoFileInfoRequest, getvideoFileInfo),
    yield takeLatest(captureVideoActivityRequest, captureVideoActivity),
    yield takeLatest(getContinueWatchingRequest, getContinueWatching),
    yield takeLatest(getGlobalSearchRequest, getGlobalSearchInfo),
    yield takeLatest(resetSearchInfoRequest, resetSearchInfo),
    yield takeLatest(captureAudioActivityRequest, captureAudioActivity),
    yield takeLatest(getRadioEventsRequest, getRadioEventsData),
    yield takeLatest(getRadioHostsRequest, getRadioHostsData),
    yield takeLatest(getRadioSponsorsRequest, getRadioSponsorsData),
    yield takeLatest(
      registerForRadioShowEventRequest,
      registerForRadioShowEvent,
    ),
    yield takeLatest(getWeeklyRadioEventsRequest, getWeeklyRadioEventsData),
    yield takeLatest(sendFeedback, sendFeedbacks),
  ])
}
