import { baseAxios } from 'api/axios'
import { apiEndPoints } from 'api/variables'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  actions,
  getLatestWebSeriesOrTalkShowsInfoRequest,
  getSimilarWebSeriesOrTalkShowsRequest,
  getWebSeriesSeasonsAndEpisodesRequest,
  getEpisodesFromWebSeriesSeasonRequest,
  getWebSeriesInfoRequest,
  getEpisodeInfoRequest,
} from './slice'

function* getLatestWebSeriesOrTalkShowsInfo({ webSeriesType }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.series.getLatestWebSeriesOrTalkShowsInfo(webSeriesType),
    )
    if (response.data.length)
      yield put(actions.setLatestWebSeriesorTalkshowList(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* getSimilarWebSeriesOrTalkShows({
  payload: { webSeriesType, genre, webSeriesId },
}: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.series.getSimilarWebSeriesOrTalkShows(
        webSeriesType,
        genre,
        webSeriesId,
      ),
    )

    if (response.data.length)
      yield put(actions.setSimilarWebSeriesorTalkshowList(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* getWebSeriesSeasonsAndEpisodes({
  payload: { webSeriesId },
}: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.series.getWebSeriesSeasonsAndEpisodes(webSeriesId),
    )

    if (response.data)
      yield put(actions.setWebSeriesSeasonsAndEpisodes(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* getEpisodesFromWebSeriesSeason({
  payload: { webSeriesId, seasonId },
}: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.series.getEpisodesFromWebSeriesSeason(webSeriesId, seasonId),
    )

    if (response.data.length)
      yield put(actions.setWebSeriesSeasonEpisodes(response.data))
  } catch (err) {
    console.log(err)
  }
}

function* getWebSeriesInfo({ payload: { webSeriesId } }: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.series.getWebSeriesInfo(webSeriesId),
    )
    if (response.data) {
      yield put(actions.setWebSeriesInfo(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

function* getEpisodeInfo({
  payload: { webSeriesId, seasonId, episodeId },
}: any): any {
  try {
    const response = yield call(
      baseAxios.get,
      apiEndPoints.series.getEpisodeInfo(webSeriesId, seasonId, episodeId),
    )
    if (response.data) {
      yield put(actions.setEpisodeInfo(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* seriesSaga(): any {
  yield all([
    yield takeLatest(
      getLatestWebSeriesOrTalkShowsInfoRequest,
      getLatestWebSeriesOrTalkShowsInfo,
    ),
    yield takeLatest(
      getSimilarWebSeriesOrTalkShowsRequest,
      getSimilarWebSeriesOrTalkShows,
    ),
    yield takeLatest(
      getWebSeriesSeasonsAndEpisodesRequest,
      getWebSeriesSeasonsAndEpisodes,
    ),
    yield takeLatest(
      getEpisodesFromWebSeriesSeasonRequest,
      getEpisodesFromWebSeriesSeason,
    ),
    yield takeLatest(getWebSeriesInfoRequest, getWebSeriesInfo),
    yield takeLatest(getEpisodeInfoRequest, getEpisodeInfo),
  ])
}
