import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/RootState'
import { initialState } from './slice'

const selectDomain = (state: RootState) => state.category || initialState

export const selectCategory = createSelector(
  [selectDomain],
  category => category,
)
